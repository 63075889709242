import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaTools } from "react-icons/fa";

const CommunicationTemplates = (props) => {
  const [cTemplates, setCTemplates] = useState([]);

  const fetchCommunicationTemplates = () => {
    Axios.get("/api/communication_templates")
      .then((res) => {
        setCTemplates(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCommunicationTemplates();
  }, []);

  const statusIcon = (status) => {
    let statusColor = "";
    if (status === "Operational") {
      statusColor = "green";
    } else if (status === "Issue") {
      statusColor = "red";
    }

    return <span className={"dot mr-2 " + statusColor}></span>;
  };

  return (
    <div className="container-fluid mt-2">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-4">
                <h2>
                  <b>Communication Templates</b>
                </h2>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Template Name</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {cTemplates.map((cTemplate, index) => (
                <tr key={cTemplate.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{cTemplate.template_name}</td>
                  <td>
                    {statusIcon(cTemplate.status)}
                    <span>
                      {cTemplate.status}{" "}
                      {cTemplate.status_updated_at && moment(cTemplate.status_updated_at).format("YYYY-MM-DD LT")}
                    </span>
                  </td>
                  <td>
                    <button
                      className="btn text-success px-1"
                      title="Edit"
                      onClick={() => {
                        props.history.push(`/communication-templates/${cTemplate.id}`);
                      }}
                    >
                      <FaTools />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CommunicationTemplates;
