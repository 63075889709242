import { useMsal } from "@azure/msal-react";
import axios from "axios";
import React, { useState } from "react";
import { Button, Input } from "../../components";
const LoginForm = ({ setUser, isError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { instance, inProgress } = useMsal();

  const onSubmit = (e) => {
    e.preventDefault();
    if (email && password) {
      axios
        .post("/api/auth", { email, password })
        .then((result) => {
          if (result.status === 200) {
            setUser(result.data);
          }
        })
        .catch((error) => alert("Please enter a valid email and password"));
    } else {
      alert("Please enter a valid email and password");
    }
  };

  const handleLogin = (instance) => {
    instance.loginRedirect().catch((e) => {
      console.error(e);
    });
  };

  const onInputChange = (e, cb) => {
    const { value } = e.target;
    return cb(value);
  };
  return (
    <div className="container">
      {isError && (
        <div className="alert alert-danger">
          {"Email not registered. Please contact administrator to register your email to the system."}
        </div>
      )}
      <form className="col-6 mx-auto mt-3" onSubmit={onSubmit}>
        <Input title="Email" type="email" autoComplete="email" onChange={(e) => onInputChange(e, setEmail)} />

        <Input
          type="password"
          title="Password"
          autoComplete="password"
          onChange={(e) => onInputChange(e, setPassword)}
        />

        <div className="row justify-content-center">
          <Button type="submit" title="Login" className="mr-4" disabled={inProgress !== "none"} />
          <Button
            type="button"
            title="Login with Office 365"
            style={{ backgroundColor: "#FF3300" }}
            disabled={inProgress !== "none"}
            onClick={() => handleLogin(instance)}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
