import axios from "axios";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { FaRegEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import "../../../../styles/date-range.css";
import "../../../../styles/pagination.css";
import { locale } from "../../../../utilities/common";
import AuctionWinnerView from "./components/AuctionWinnerView";
import { PAGINATION_CURRENT_PAGE, PAGINATION_PAGE_SIZE } from "./config";

function AuctionWinners() {
  const [auctionWinners, setAuctionWinners] = useState([]);
  const [viewIndex, setViewIndex] = useState(-1);
  const [visibleType, setVisibleType] = useState("");
  const [currentPage, setCurrentPage] = useState(PAGINATION_CURRENT_PAGE);
  const [currentSort, setCurrentSort] = useState("sort");
  const [filteredList, setFilteredList] = useState([]);
  const [filteredListWithPaginate, setFilteredListWithPaginate] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleToggleSort = () => {
    if (currentSort === "sort") {
      setCurrentSort("sort-up");
    } else if (currentSort === "sort-up") {
      setCurrentSort("sort-down");
    } else if (currentSort === "sort-down") {
      setCurrentSort("sort-up");
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };

  const fetchAuctionWinners = () => {
    axios.get("/api/auction_winners").then((res) => {
      setAuctionWinners(res.data || []);
    });
  };

  useEffect(() => {
    fetchAuctionWinners();
  }, []);

  const sendEmails = () => {
    const confirmed = window.confirm("Are you sure to send auction winner email to these customers?");
    if (!confirmed) return;
    axios
      .post(`/api/auction_winners/send_emails`)
      .then((result) => {
        window.alert("Done");
        fetchAuctionWinners();
      })
      .catch((error) => {
        window.alert(error);
        console.log(error);
      });
  };

  const handleUnsent = (e) => {
    if (e.target.checked) {
      setVisibleType("unsent");
    } else {
      setVisibleType("");
    }
  };

  const markAsSent = (id, email) => {
    const confirmed = window.confirm(`Are you sure to mark this customer email ${email} as sent?`);
    if (!confirmed) return;
    axios
      .post(`/api/auction_winners/${id}/mark_as_sent`)
      .then((result) => {
        window.alert("Done");
        fetchAuctionWinners();
      })
      .catch((error) => {
        window.alert(error);
        console.log(error);
      });
  };

  useEffect(() => {
    if (visibleType === "unsent") {
      setFilteredList(auctionWinners.filter((w) => !w.sent_at));
    } else {
      setFilteredList(auctionWinners);
    }
  }, [visibleType, auctionWinners]);

  useEffect(() => {
    if (currentSort === "sort-up") {
      // ascending sort
      filteredList.sort((auc1, auc2) => {
        if (new Date(auc1.createdAt).getTime() < new Date(auc2.createdAt).getTime()) return -1;
        if (new Date(auc1.createdAt).getTime() > new Date(auc2.createdAt).getTime()) return 1;
        return 0;
      });
    } else if (currentSort === "sort-down") {
      // descending sort
      filteredList.sort((auc1, auc2) => {
        if (new Date(auc1.createdAt).getTime() > new Date(auc2.createdAt).getTime()) return -1;
        if (new Date(auc1.createdAt).getTime() < new Date(auc2.createdAt).getTime()) return 1;
        return 0;
      });
    }
    setFilteredList([...filteredList]);
  }, [currentSort]);

  useEffect(() => {
    const slice = filteredList.slice(
      (currentPage - 1) * PAGINATION_PAGE_SIZE,
      (currentPage - 1) * PAGINATION_PAGE_SIZE + PAGINATION_PAGE_SIZE
    );
    setFilteredListWithPaginate([...slice]);
  }, [currentPage, filteredList]);

  useEffect(() => {
    if (startDate && endDate) {
      if (visibleType === "unsent") {
        setFilteredList(
          auctionWinners.filter(
            (w) =>
              !w.sent_at &&
              new Date(w.sent_at).getTime() >= new Date(new Date(startDate).setUTCHours(0, 0, 0, 0)).getTime() &&
              new Date(w.sent_at).getTime() <= new Date(new Date(endDate).setUTCHours(23, 59, 59, 999)).getTime()
          )
        );
      } else {
        setFilteredList(
          auctionWinners.filter(
            (w) =>
              new Date(w.sent_at).getTime() >= new Date(new Date(startDate).setUTCHours(0, 0, 0, 0)).getTime() &&
              new Date(w.sent_at).getTime() <= new Date(new Date(endDate).setUTCHours(23, 59, 59, 999)).getTime()
          )
        );
      }
    } else {
      if (visibleType === "unsent") {
        setFilteredList(auctionWinners.filter((w) => !w.sent_at));
      } else {
        setFilteredList(auctionWinners);
      }
    }
  }, [startDate, endDate, auctionWinners]);

  return (
    <div className="container-fluid mt-2">
      <div className="row d-flex align-items-center justify-content-end">
        <div className="mx-4">
          <label className="form-check-label" style={{ marginBottom: 0 }}>
            <input type="checkbox" className="form-check-input" onClick={handleUnsent} />
            Unsent
          </label>
        </div>
        <div className="mx-4 my-2">
          <label className="mx-4" style={{ marginBottom: 0 }} htmlFor="filter">
            Filter sent at:
          </label>
          <DateRangePicker
            startDate={startDate}
            startDateId="start_date_id"
            endDate={endDate}
            endDateId="end_date_id"
            displayFormat="MM/DD/YYYY"
            isOutsideRange={() => false}
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            regular
          />
        </div>
      </div>
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-8">
                <h2>
                  <b>Auction Winners</b>
                </h2>
              </div>

              <div className="col-sm-4">
                <Link to="/auctions/new-upload" className="btn btn-success float-right">
                  Upload CSV
                </Link>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th className="text-center">ID</th>
                <th>Auction ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Location Code</th>
                <th>Phone</th>
                <th>Address</th>
                <th>City</th>
                <th>Zip</th>
                <th>Facility Balance</th>
                <th onClick={handleToggleSort} style={{ cursor: "pointer" }}>
                  {"Uploaded time"} &nbsp;
                  <i className={`fa-solid fa-${currentSort}`}></i>
                </th>
                <th>Status</th>
                <th>Sent At</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredListWithPaginate.map((item, index) => (
                <tr key={item.email + item.id}>
                  <td className="text-center">{item.id}</td>
                  <td>{item.auction_id}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.location_code}</td>
                  <td>{item.phone}</td>
                  <td>{item.address}</td>
                  <td>{item.city}</td>
                  <td>{item.zip}</td>
                  <td>${(item.facility_balance || 0).toFixed(2)}</td>
                  <td>{item.createdAt}</td>
                  <td>{item.status}</td>
                  <td>{item.sent_at}</td>
                  <td className="text-center actions">
                    <button className="btn text-success" title="View" onClick={() => setViewIndex(index)}>
                      <FaRegEye />
                    </button>
                    <button
                      className="btn text-danger px-1"
                      title="Mark as sent"
                      onClick={() => markAsSent(item.id, item.email)}
                    >
                      <FaPen />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-sm-12 text-right">
          <Pagination
            current={currentPage}
            onChange={onChange}
            pageSize={PAGINATION_PAGE_SIZE}
            total={filteredList.length}
            hideOnSinglePage
            locale={locale}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <button type="button" className="btn btn-primary add-new float-right" onClick={() => sendEmails()}>
            Send Email To Auction Winners
          </button>
        </div>
      </div>
      {viewIndex > -1 && (
        <AuctionWinnerView data={filteredListWithPaginate[viewIndex]} onClose={() => setViewIndex(-1)} />
      )}
    </div>
  );
}

export default AuctionWinners;
