import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { FaEye } from "react-icons/fa";
import Pagination from "rc-pagination";
export const PAGINATION_PAGE_SIZE = 20;
export const PAGINATION_CURRENT_PAGE = 1;

const CommunicationModal = (props) => {
  return (
    <>
      <div className="modal fade communication-modal show" tabIndex="-1" role="dialog">
        <div className="modal-body" role="document">
          <span className="modal-close-btn" onClick={props.onClose}>
            <GrClose />
          </span>
          <div dangerouslySetInnerHTML={{ __html: props.html }} />
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={() => props.onClose()}></div>
    </>
  );
};

const CommunicationTemplates = (props) => {
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [selected, setSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(PAGINATION_CURRENT_PAGE);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchCommunications();
  }, [currentPage]);

  const fetchCommunications = () => {
    const offset = (currentPage - 1) * PAGINATION_PAGE_SIZE;
    Axios.get(`/api/communications?limit=${PAGINATION_PAGE_SIZE}&offset=${offset}`)
      .then((res) => {
        const result = res.data;
        setItems(result.rows);
        setTotalCount(result.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid mt-2">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-4">
                <h2>
                  <b>Communication History</b>
                </h2>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Sent At</th>
                <th scope="col">Facility</th>
                <th scope="col">Type</th>
                <th scope="col">Email/Phone</th>
                <th scope="col">Template</th>
                <th scope="col">Status</th>
                <th scope="col" className="text-center">
                  Preview
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{moment(item.createdAt).format("YYYY-MM-DD LT")}</td>
                  <td>{item.prospect?.site_name}</td>
                  <td>{item.method}</td>
                  <td>{item.method === "email" ? item.prospect?.email_address : item.prospect?.cell_phone}</td>
                  <td>{item.communication_template?.email_subject}</td>
                  <td className={item.status === "success" ? "text-success" : "text-danger"}>{item.status}</td>
                  <td className="text-center">
                    <button className="btn text-success px-1" title="Edit" onClick={() => setSelected(item)}>
                      <FaEye />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={9} className="text-right">
                  <Pagination
                    current={currentPage}
                    onChange={onPageChange}
                    pageSize={PAGINATION_PAGE_SIZE}
                    total={totalCount}
                    hideOnSinglePage
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {selected && <CommunicationModal html={selected.html_body || "Empty"} onClose={() => setSelected(null)} />}
    </div>
  );
};

export default CommunicationTemplates;
