import React from "react";

function Unauthorized() {
  return (
    <div className="wrapper">
      <h1 className="text-center mt-4">{`Permission required! You're not authorized for this page.`}</h1>
    </div>
  );
}

export default Unauthorized;
