import React from "react";

const DropDown = ({ value, title, options = [], onChange = () => {}, includeBlank = null, required = false }) => {
  return (
    <div className="form-group row">
      <label className="col-sm-4 d-flex align-items-center">{title}</label>
      <select value={value} className="col-sm-8 form-control" onChange={onChange} required>
        {includeBlank && <option>{includeBlank}</option>}
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropDown;
