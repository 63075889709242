import React from "react";
import { BrowserRouter } from "react-router-dom";
import { getRouterBySubDomain } from "./utilities/helper";
import "react-mde/lib/styles/css/react-mde-all.css";

function App() {
  const subDomain = window.location.host.split(".")[0];
  const RouterBySubDomain = getRouterBySubDomain(subDomain);

  return (
    <div className="App">
      <BrowserRouter>
        <RouterBySubDomain />
      </BrowserRouter>
    </div>
  );
}

export default App;
