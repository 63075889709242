import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import { FaMinusCircle } from "react-icons/fa";

function LibraryEdit(props) {
  const [topic, setTopic] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [article, setArticle] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");
  const { id } = props.match.params;

  const newKeyword = () => {
    setKeywords(keywords.concat(""));
  };

  const changeKeyword = (index, value) => {
    keywords[index] = value;
    setKeywords([...keywords]);
  };

  const removeKeyword = (index) => {
    keywords.splice(index, 1);
    setKeywords([...keywords]);
  };

  const fetchLibrary = () => {
    axios.get(`/api/libraries/${id}`).then((res) => {
      const data = res.data;

      if (!data) {
        alert("Library not found");
        props.history.push("/faq");
        return;
      }

      setTopic(data.topic);
      setArticle(data.article);
      setKeywords(data.keywords || []);
    });
  };

  useEffect(() => {
    fetchLibrary();
  }, []);

  const handleSubmit = () => {
    const data = {
      topic: topic,
      keywords: keywords.map((keyword) => keyword.toLowerCase()),
      article: article,
    };

    axios.put(`/api/libraries/${id}`, data).then((res) => {
      if (res.status === 200) {
        alert("Successfully updated the contents!");
        props.history.push("/faq");
      } else {
        alert("Failed to update contents.");
      }
    });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Edit KB Article</h1>

      <div className="row py-2">
        <div className="col-4 text-right">Topic</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input w-100" value={topic} onChange={(e) => setTopic(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Keywords</div>
        <div className="col-8 text-left">
          {keywords.map((keyword, index) => (
            <div key={"keyword" + index}>
              <input
                type="text"
                className="form-input mb-1 w-75"
                value={keyword}
                onChange={(e) => changeKeyword(index, e.target.value)}
              />
              <span
                className="ml-3 cursor-pointer action-btn text-danger"
                title="Delete Keyword"
                onClick={() => removeKeyword(index)}
              >
                <FaMinusCircle />
              </span>
            </div>
          ))}
          <button className="btn btn-primary" onClick={newKeyword}>
            Add New Keyword
          </button>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Article</div>
        <div className="col-8 text-left">
          <ReactMde
            value={article}
            onChange={setArticle}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/faq">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default LibraryEdit;
