import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaDownload, FaRegEye } from "react-icons/fa";
import moment from "moment";
import Pagination from "rc-pagination";
import { convertJsonToCsv, downloadCsvFile, locale } from "../../utilities/common";
export const PAGINATION_PAGE_SIZE = 10;
export const PAGINATION_CURRENT_PAGE = 1;

function LeaseTerminationsHistory() {
  const [terminations, setTerminations] = useState([]);
  const [currentPage, setCurrentPage] = useState(PAGINATION_CURRENT_PAGE);
  const [filteredListWithPaginate, setFilteredListWithPaginate] = useState([]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchLeaseTerminations = () => {
    axios.get("/api/lease_terminations").then((res) => {
      const rows = res.data;
      rows.forEach((e) => (e.facility = e.location.name)); // alias facility=location_code
      setTerminations(res.data || []);
    });
  };

  useEffect(() => {
    fetchLeaseTerminations();
  }, []);

  useEffect(() => {
    const slice = terminations.slice(
      (currentPage - 1) * PAGINATION_PAGE_SIZE,
      (currentPage - 1) * PAGINATION_PAGE_SIZE + PAGINATION_PAGE_SIZE
    );
    setFilteredListWithPaginate([...slice]);
  }, [currentPage, terminations]);

  const downloadHistory = async () => {
    const csvContent = convertJsonToCsv(terminations, [
      "id",
      "facility",
      "units",
      "sent_at",
      "termination_type",
      "reason",
      "termination_date",
      "termination_time",
      "viewed_at",
      "createdAt",
    ]);
    downloadCsvFile(csvContent, "lease_termination_history.csv");
  };

  return (
    <>
      <div className="px-4">
        <button
          className="btn btn-primary btn-sm float-right"
          style={{ height: 38 }}
          title="Download History As CSV"
          onClick={() => downloadHistory()}
        >
          <FaDownload className="mr-2" />
          Download
        </button>
        <Link className="mx-4 btn btn-dark float-right" title="Lease Terminations" to="/lease-terminations">
          Back
        </Link>
      </div>
      <div className="container-fluid mt-2">
        <div className="table-responsive">
          <div className="table-wrapper">
            <div className="table-title">
              <h2 className="text-center">
                <b>Lease Terminations History</b>
              </h2>
            </div>
            <table className="table table-striped table-hover table-bordered bg-white">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Sent At</th>
                  <th>Facility</th>
                  <th>Unit(s)</th>
                  <th>Type</th>
                  <th>Reason</th>
                  <th>Termination Date</th>
                  <th>Termination Time</th>
                  <th>Tenant Viewed</th>
                  <th>Notice</th>
                </tr>
              </thead>
              <tbody>
                {filteredListWithPaginate.map((item, index) => (
                  <tr key={"termination_" + item.id}>
                    <td>{PAGINATION_PAGE_SIZE * (currentPage - 1) + index + 1}</td>
                    <td>{moment(item.sent_at).format("l LT")}</td>
                    <td>{item.location?.name}</td>
                    <td>{item.units.join(", ")}</td>
                    <td>{item.termination_type}</td>
                    <td>{item.reason}</td>
                    <td>{item.termination_date}</td>
                    <td>{item.termination_time}</td>
                    <td>{item.viewed_at && moment(item.viewed_at).format("l LT")}</td>
                    <td className="text-center actions">
                      <a
                        className="action-btn text-success"
                        title="View Notice"
                        target="_blank"
                        href={item.notice_path}
                        rel="noreferrer"
                      >
                        <FaRegEye />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={9} className="text-right">
                    <Pagination
                      current={currentPage}
                      onChange={onPageChange}
                      pageSize={PAGINATION_PAGE_SIZE}
                      total={terminations.length}
                      hideOnSinglePage
                      locale={locale}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaseTerminationsHistory;
