import Axios from "axios";
import React, { useState, useEffect } from "react";
import { BULK_EDIT_KEYS } from "./constants";

function LocationBulkEdit(props) {
  const [locations, setLocations] = useState([]);

  const fetchLocations = async () => {
    const res1 = await Axios.get("/api/locations");
    const locationList = res1.data || [];

    const locationContents = await Promise.all(locationList.map((l) => fetchLocationContent(l.location_code)));

    locationList.forEach((l) => {
      const content = locationContents.find((e) => e.location_code === l.location_code);

      l.cms_content = content;
    });

    setLocations(locationList);
  };

  const fetchLocationContent = (locationCode) => {
    return Axios.get("/api/location_contents/" + locationCode).then((res) => res.data);
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleChange = (location, key, value) => {
    console.log(location, key, value);
    const targetLocation = locations.find((l) => l.location_code === location.location_code);
    if (key === "reservation_window") {
      targetLocation[key] = value;
    } else {
      targetLocation.cms_content[key] = value;
    }

    setLocations(locations);
  };

  const extractCmsValues = (location) => {
    const result = {};

    const keys = BULK_EDIT_KEYS.map((e) => e.key);

    keys.forEach((key) => (result[key] = location.cms_content[key]));

    return result;
  };

  const handleSave = async () => {
    for (const location of locations) {
      await Axios.put(`/api/locations/${location.id}`, { reservation_window: location.reservation_window });
      await Axios.put(`/api/location_contents/${location.location_code}`, extractCmsValues(location));
    }
    alert("Saved!");
  };

  const renderInput = (location, header) => {
    if (header.type === "checkbox") {
      return (
        <input
          style={{ width: 60 }}
          type="checkbox"
          onChange={(e) => handleChange(location, header.key, e.target.checked)}
          defaultChecked={location.cms_content[header.key]}
        />
      );
    } else {
      return (
        <input
          style={{ width: 150 }}
          type={header.type}
          onChange={(e) => handleChange(location, header.key, e.target.value)}
          defaultValue={location.cms_content[header.key] || location[header.key]}
        />
      );
    }
  };

  return (
    <div className="container-fluid mt-2">
      <div className="table-title">
        <div className="row">
          <div className="col-sm-4">
            <h2>
              <b>Location Bulk Edit</b>
            </h2>
          </div>
          <div className="col-sm-8">
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-info add-new" onClick={handleSave}>
                Update All Pages
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <div className="table-wrapper">
          <table className="table table-striped table-hover table-bordered bg-white custom-table">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Location Code</th>
                <th className="text-center">Name</th>
                {BULK_EDIT_KEYS.map((header) => (
                  <th
                    style={{ minWidth: header.minWidth }}
                    key={header.key}
                    className={`${header.className} text-center`}
                  >
                    {header.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {locations.map((location, index) => (
                <tr key={location.location_code}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{location.location_code}</td>
                  <td>{location.name}</td>
                  {BULK_EDIT_KEYS.map((header) => (
                    <th key={header.key} className={`${header.className} text-center`}>
                      {renderInput(location, header)}
                    </th>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LocationBulkEdit;
