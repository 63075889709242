import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaTrash, FaPlus, FaTools } from "react-icons/fa";

function States() {
  const [states, setStates] = useState([]);

  const fetchStates = () => {
    axios.get("/api/states").then((res) => {
      setStates(res.data || []);
    });
  };

  const handleDeleteState = (shortName) => {
    if (window.confirm("Are you sure you want to delete this state?")) {
      axios.delete("/api/states/" + shortName).then((res) => {
        if (res.status === 200) {
          fetchStates();
        }
      });
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  return (
    <div className="container-fluid mt-2">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-4">
                <h2>
                  <b>States</b>
                </h2>
              </div>
              <div className="col-sm-8">
                <Link className="btn btn-info add-new float-right" title="New State" to={`/states/new`}>
                  <FaPlus /> New State
                </Link>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>State</th>
                <th>State Name</th>
                <th>Photo</th>
                <th className="text-center" style={{ width: "120px" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {states.map((state, index) => (
                <tr key={state.short_name}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{state.short_name}</td>
                  <td>{state.full_name}</td>
                  <td>{state.image_src}</td>
                  <td className="text-center actions">
                    <span
                      className="action-btn text-danger px-1"
                      title="Delete"
                      onClick={() => handleDeleteState(state.short_name)}
                    >
                      <FaTrash />
                    </span>
                    <Link
                      className="action-btn text-success px-1"
                      title="Edit CMS Content"
                      to={`/states/${state.short_name}`}
                    >
                      <FaTools />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default States;
