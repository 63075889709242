import React, { useEffect, useState } from "react";
import axios from "axios";
import RoleForm from "./RoleForm";
import { FaPen, FaTrash } from "react-icons/fa";
import RoleService from "../../services/roles";
import { Link } from "react-router-dom";
import { Button, Modal } from "../../components";
import { useUser } from "../../utilities/helper";
import { ROLES } from "../../utilities/constants";
import { PAGES_UNDER_ROLE } from "../../utilities/constants";

const RolesPage = () => {
  const [roles, setRoles] = useState([]);
  const [editData, setEditData] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const { user: userInfo } = useUser();

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    const result = await RoleService.fetchRoles();
    setRoles(result);
  };

  const handleDeleteRole = async (role) => {
    if (role.assigned_users > 0) {
      alert("You can't delete this role since this role is being used by users.");
      return;
    }
    const isConfirm = confirm("Are you sure you want to delete this role?");
    if (isConfirm) {
      const response = await RoleService.deleteRole(role.id);
      if (response.status === 200) {
        alert("Role was successfully deleted");
        setRoles(roles.filter((role) => role.id !== role.id));
      }
    }
  };

  const closeModal = () => {
    setEditData(null);
    setVisibleModal(false);
  };

  const handleChange = (e, role, path) => {
    let new_pages = role.allowed_pages;

    if (e.target.checked) {
      new_pages.push(path);
    } else {
      new_pages = new_pages.filter((e) => e !== path);
    }
    role.allowed_pages = [...new Set(new_pages)]; // remove duplicated

    console.log(role.allowed_pages);

    RoleService.updateRole(role);
  };

  if (!userInfo.role.superadmin) {
    return "Permission required!";
  }

  return (
    <div className="container-fluid mt-2">
      <div className="mt-2">
        <div className="row justify-content-end">
          <Button
            title="Create Role"
            onPress={() => {
              setVisibleModal(true);
              setEditData(null);
            }}
          />
        </div>

        <h1 className="text-center">User Roles</h1>

        <Modal visible={visibleModal} setVisible={closeModal} title={editData ? "Edit Role" : "Create Role"}>
          <RoleForm editData={editData} onClose={() => closeModal()} onFinish={() => fetchRoles()} />
        </Modal>

        <div className="col-12 mx-auto">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">User Role</th>
                {PAGES_UNDER_ROLE.map((e) => (
                  <th key={"th" + e.path} scope="col" className="text-center role-table-td" title={e.name}>
                    {e.name}
                  </th>
                ))}
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {roles.sort().map((role, index) => (
                <tr key={role.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{role.name}</td>
                  {PAGES_UNDER_ROLE.map((e) => (
                    <td key={"td" + role.id + e.path} scope="col" className="text-center">
                      <input
                        type="checkbox"
                        defaultChecked={role.allowed_pages.includes(e.path)}
                        onChange={(e2) => handleChange(e2, role, e.path)}
                      ></input>
                    </td>
                  ))}
                  <td>
                    <button
                      className="btn text-warning px-1"
                      title="Edit"
                      onClick={() => {
                        setEditData(role);
                        setVisibleModal(true);
                      }}
                    >
                      <FaPen />
                    </button>
                    <button className="btn text-danger px-1" onClick={() => handleDeleteRole(role)} title="Delete">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RolesPage;
