import axios from "axios";

// Get list roles
const FETCH_ROLE_FAILED = "Error occurred while fetching roles";
const fetchRoles = async () => {
  try {
    const response = await axios.get("api/roles");
    return response.data;
  } catch (error) {
    const message = error.response.data.message || FETCH_ROLE_FAILED;
    alert(message);
  }
};

// Create new role
const CREATE_ROLE_FAILED = "Error occurred while creating new role";
const createRole = async (data) => {
  try {
    const response = await axios.post("api/roles", data);
    return response;
  } catch (error) {
    const message = error.response.data.message || CREATE_ROLE_FAILED;
    alert(message);
  }
};

// Delete existing role
const deleteRole = async (roleId) => {
  try {
    const response = await axios.delete("api/roles", { params: { roleId } });
    return response;
  } catch (error) {
    alert(error);
  }
};

const updateRole = async (data) => {
  try {
    const response = await axios.put("api/roles", data);
    return response;
  } catch (error) {
    alert(error);
  }
};

export default { fetchRoles, createRole, deleteRole, updateRole };
