import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { BASE_URL_ENV } from "../SnapshotFacilityTasks/constants";
import styles from "./styles.module.css";
import getAxiosWithDefaultHeaders from "../config";

const TABLE_FIELDS = [
  {
    header: "Facility",
    key: "facility_name",
  },
  {
    header: "Open Facility Tasks",
    key: "open_facility_tasks_count",
  },
  {
    header: "Open Unit Tasks",
    key: "open_unit_tasks_count",
  },
  {
    header: "Oldest Task",
    key: "oldest_task_in_day",
  },
  {
    header: "Max Inactivity",
    key: "max_inactivity_in_day",
  },
  {
    header: "Assigned To",
    key: "assigned_to",
  },
];

function SnapshotTasksOverview() {
  let [tasks, setTasks] = useState([]);
  let [unfilteredTasks, setUnfilteredTasks] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [currentSort, setCurrentSort] = useState({ facility_name: "sort-up" });
  const [search, setSearch] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const Axios = getAxiosWithDefaultHeaders();

  const getSiteNumber = (facilityName) => {
    return parseInt(facilityName.split(" ").pop());
  };

  const fetchSnapshotTasksOverview = async () => {
    setIsLoading(true);
    try {
      const result = await Axios(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/tasks/overview`);
      result.data = result.data.sort((t1, t2) => {
        const siteNum1 = getSiteNumber(t1.facility_name);
        const siteNum2 = getSiteNumber(t2.facility_name);
        return isNaN(siteNum1 - siteNum2) ? 0 : siteNum1 - siteNum2;
      });
      setTasks(result.data);
      setUnfilteredTasks(result.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsLoaded(true);
    }
  };

  const filterSearchResult = async (unfilteredTasks, search) => {
    setIsLoading(true);
    try {
      const result = await Axios(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/tasks/search?query=${search}`);
      const filterTasks = unfilteredTasks
        .filter((task) => result.data.site_codes.includes(task.site_code))
        .sort((t1, t2) => {
          const siteNum1 = getSiteNumber(t1.facility_name);
          const siteNum2 = getSiteNumber(t2.facility_name);
          return isNaN(siteNum1 - siteNum2) ? 0 : siteNum1 - siteNum2;
        });
      setTasks(filterTasks);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFilterSearchResult = useCallback(
    debounce((unfilteredTasks, search) => {
      if (search) {
        filterSearchResult(unfilteredTasks, search);
      } else {
        fetchSnapshotTasksOverview();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    fetchSnapshotTasksOverview();
  }, []);

  useEffect(() => {
    if (!isLoaded) return;
    debouncedFilterSearchResult(unfilteredTasks, search);
  }, [search]);

  const toggleSort = (key) => {
    let currSort = currentSort[key] || "sort";
    if (currSort === "sort") {
      currSort = "sort-up";
    } else if (currSort === "sort-up") {
      currSort = "sort-down";
    } else if (currSort === "sort-down") {
      currSort = "sort-up";
    }
    sort(key, currSort);
    setCurrentSort({ [key]: currSort });
  };

  const sort = (key, currSort) => {
    tasks = tasks.sort((t1, t2) => {
      if (key === "facility_name") {
        const siteNum1 = getSiteNumber(t1.facility_name);
        const siteNum2 = getSiteNumber(t2.facility_name);
        const sortIndex = currSort === "sort-up" ? siteNum1 - siteNum2 : siteNum2 - siteNum1;
        return isNaN(sortIndex) ? 0 : sortIndex;
      }
      if (key === "assigned_to") {
        return currSort === "sort-up"
          ? t1.assigned_to > t2.assigned_to
            ? 1
            : -1
          : t1.assigned_to > t2.assigned_to
          ? -1
          : 1;
      }
      return currSort === "sort-up" ? parseInt(t1[key]) - parseInt(t2[key]) : parseInt(t2[key]) - parseInt(t1[key]);
    });
    setTasks([...tasks]);
  };

  const getAssignedToRole = (roles) => {
    const rolesName = {
      property_manager: "PM",
      regional_manager: "RM",
      customer_service: "CS",
    };
    return roles
      .split(", ")
      .map((role) => rolesName[role])
      .join(", ");
  };

  return (
    <div>
      <Helmet>
        <title>Tasks Overview</title>
      </Helmet>
      <SnapShotHeader />

      <div className="col-12 bg-light min-vh-100">
        <div className="col-10 mx-auto py-3">
          <div className="d-flex align-items-center justify-content-between">
            <p className={styles.topHeader}>Task Overview</p>
          </div>
          <div className="input-group mt-4 mb-4">
            <input
              className="form-control py-2 border-right-0 border"
              type="search"
              value={search}
              placeholder="Search by Unit Number or Task Type..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="input-group-append">
              <button className="btn btn-outline-secondary border-left-0 border" type="button">
                <i className="fa fa-search"></i>
              </button>
            </span>
          </div>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                {TABLE_FIELDS.map((field) => (
                  <th
                    key={field.key}
                    scope="col"
                    onClick={() => toggleSort(field.key)}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    {field.header} &nbsp;
                    <i className={`fa-solid fa-${currentSort[field.key] || "sort"}`}></i>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div className="text-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
              {!isLoading && tasks.length !== 0
                ? tasks.map((task) => (
                    <tr key={task.site_code}>
                      <th scope="row">
                        <Link to={`/snapshot/facility-tasks/${task.site_code}`}>{task.facility_name}</Link>
                      </th>
                      <td className="text-right">{task.open_facility_tasks_count}</td>
                      <td className="text-right">{task.open_unit_tasks_count}</td>
                      <td className={`text-right days-old ${task.oldest_task_in_day >= 7 ? "warning" : ""}`}>
                        {task.oldest_task_in_day + " Days"}
                      </td>
                      <td className={`text-right days-old ${task.max_inactivity_in_day >= 7 ? "warning" : ""}`}>
                        {task.max_inactivity_in_day + " Days"}
                      </td>
                      <td className="text-left">{task.assigned_to && getAssignedToRole(task.assigned_to)}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SnapshotTasksOverview;
