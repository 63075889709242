import React, { useEffect, useState } from "react";
import { getRandomIntWithoutSequential } from "../utilities/common";
import Modal from "./Modal";

const SnapShotHeader = () => {
  const [generateGateCodeVisibleModal, setGenerateGateCodeVisibleModal] = useState(false);
  const [generateGateCode, setGenerateGateCode] = useState("");

  useEffect(() => {
    if (generateGateCodeVisibleModal) {
      handleGenerateGateCode();
    }
  }, [generateGateCodeVisibleModal]);

  const handleGenerateGateCode = () => {
    const gateCode = getRandomIntWithoutSequential();
    setGenerateGateCode(gateCode);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark py-3 mx-3 bg-white" id="header">
      <div className="container-fluid">
        <div className="navbar-brand">
          <a href="/">
            <img src="/snapshot-logo.png" alt="logo" style={{ width: "8.5rem", cursor: "pointer" }} />
          </a>
        </div>
        <button
          id="generate-gate-code-btn"
          className="btn btn-primary mr-2"
          onClick={() => setGenerateGateCodeVisibleModal(true)}
        >
          Generate Gate Code
        </button>
      </div>
      <Modal
        visible={generateGateCodeVisibleModal}
        setVisible={() => {
          setGenerateGateCodeVisibleModal(false);
          setGenerateGateCode("");
        }}
        title="Generated Gate Code"
      >
        <input readOnly className="form-control" value={generateGateCode} />
        <button className="btn btn-success my-2" onClick={handleGenerateGateCode}>
          Generate new code
        </button>
      </Modal>
    </nav>
  );
};

export default SnapShotHeader;
