import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaTrash, FaPlus, FaTools } from "react-icons/fa";

function CityIndex() {
  const [cities, setCities] = useState([]);

  const fetchCities = () => {
    axios.get("/api/cities").then((res) => {
      setCities(res.data || []);
    });
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this city?")) {
      axios.delete("/api/city/" + id).then((res) => {
        if (res.status === 200) {
          setCities();
        }
      });
    }
  };

  useEffect(() => {
    fetchCities();
  }, []);

  return (
    <div className="container-fluid mt-2">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-4">
                <h2>
                  <b>Cities</b>
                </h2>
              </div>
              <div className="col-sm-8">
                <Link className="btn btn-info add-new float-right" title="New State" to={`/cities/new`}>
                  <FaPlus /> New City
                </Link>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>State</th>
                <th>State Name</th>
                <th>Photo</th>
                <th className="text-center" style={{ width: "120px" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {cities.map((city, index) => (
                <tr key={city.name + city.id}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{city.state}</td>
                  <td>{city.name}</td>
                  <td>{city.image_src}</td>
                  <td className="text-center actions">
                    <span className="action-btn text-danger px-1" title="Delete" onClick={() => handleDelete(city.id)}>
                      <FaTrash />
                    </span>
                    <Link className="action-btn text-success px-1" title="Edit CMS Content" to={`/cities/${city.id}`}>
                      <FaTools />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CityIndex;
