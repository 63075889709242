const MIN_WIDTH = 150;

export const BULK_EDIT_KEYS = [
  {
    key: "unit_size_high_to_low",
    title: "Unit Size High To Low",
    className: "",
    type: "checkbox",
    minWidth: 60,
  },
  {
    key: "unit_group_large_to_small",
    title: "Reverse Group Order",
    className: "",
    type: "checkbox",
    minWidth: 60,
  },
  {
    key: "recommended_unit",
    title: "Recommended Unit",
    className: "",
    type: "checkbox",
    minWidth: 60,
  },
  {
    key: "unit_groups_expanded",
    title: "Unit Groups Expanded",
    className: "",
    type: "checkbox",
    minWidth: 60,
  },
  {
    key: "fee_for_24hr_access",
    title: "24 Hour Access Fee",
    className: "",
    type: "checkbox",
    minWidth: 60,
  },
  {
    key: "reservation_window",
    title: "Reservation Window",
    className: "",
    type: "number",
    minWidth: 60,
  },
  {
    key: "title",
    title: "Title",
    className: "",
    type: "text",
  },
  {
    key: "meta_title",
    title: "Meta Title",
    className: "",
    type: "text",
  },
  {
    key: "meta_description",
    title: "Meta Description",
    className: "",
    type: "text",
  },
  {
    key: "access_hours",
    title: "Access Hours",
    className: "",
    type: "text",
  },
  {
    key: "customer_service_hours",
    title: "Customer Service Hours",
    className: "",
    type: "text",
  },
];
