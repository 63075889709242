import React from "react";
import axios from "axios";

function LocationDelete(props) {
  const { location } = props;

  const handleDelete = (e) => {
    e.preventDefault();

    axios.delete("/api/locations/" + location.id).then((res) => {
      if (res.status === 200) {
        props.onFinish();
        props.onClose();
      }
    });
  };

  return (
    <>
      <div className="modal fade location-modal show delete-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Delete Location</h4>
              <button type="button" className="close" aria-label="Close" onClick={props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-3">
              <h4>Are you sure you want to delete this location?</h4>
            </div>
            <div className="modal-footer d-flex justify-content-end">
              <button className="btn btn-indigo" onClick={props.onClose}>
                Close
              </button>
              <button className="btn btn-primary" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
}

export default LocationDelete;
