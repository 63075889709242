import React, { useState } from "react";
import { DropDown, Button, Input, Loader } from "../../components";
import { DelinquentTenantsService } from "../../services";
import SearchResult from "./components/SearchResult";

const OPTIONS_SEARCH = [
  {
    value: "search_tenant_email",
    title: "Email",
    type: "email",
  },
  {
    value: "search_tenant_sms",
    title: "SMS",
    type: "sms",
  },
];

const DelinquentTenants = ({ setUser }) => {
  const [valueSearch, setValueSearch] = useState(OPTIONS_SEARCH[0].value);
  const [query, setQuery] = useState("");
  const [resultSearch, setResultSearch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    if (!query) {
      return alert("Please fill in all the required fields.");
    }
    setIsLoading(true);
    const selectedOption = OPTIONS_SEARCH.find((option) => option.value === valueSearch);
    try {
      const response = await DelinquentTenantsService.searchTenants({
        type: selectedOption.type,
        query,
      });
      setIsLoading(false);
      if (response && response.data) {
        setResultSearch(response.data);
      } else {
        setResultSearch(null);
        alert("Not found");
      }
    } catch (error) {
      setResultSearch(null);
      setIsLoading(false);
      return alert("Not found");
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    setUser(null);
  };

  return (
    <div>
      <div className="col-12 my-2 d-flex justify-content-end">
        <Button title="Logout" onPress={handleLogout} typeBtn="dark" />
      </div>
      <div className="col-6 mx-auto">
        <h2 className="text-center">Tenant Settlements</h2>
        {isLoading ? (
          <div className="row justify-content-center my-3">
            <Loader />
          </div>
        ) : null}

        <DropDown
          key="search_tenant_dropdown"
          options={OPTIONS_SEARCH}
          title="Find tenant by"
          value={valueSearch}
          onChange={(e) => setValueSearch(e.target.value)}
        />

        <Input onChange={(e) => setQuery(e.target.value)} title="Email/Phone" />

        <div className="row justify-content-end">
          <Button title={"Search Tenant"} onPress={handleSearch} />
        </div>

        {resultSearch ? (
          <SearchResult result={resultSearch} onSubmit={DelinquentTenantsService.sendPartialLink} />
        ) : null}
      </div>
    </div>
  );
};

export default DelinquentTenants;
