const MIN_WIDTH = 150;

export const TABLE_CONFIGS = [
  {
    key: "index",
    title: "#",
    className: "text-center",
    minWidth: 20,
  },
  {
    key: "location_code",
    title: "Location Code",
    className: "text-center",
    minWidth: 20,
  },
  {
    key: "name",
    title: "Name",
    className: "",
    minWidth: MIN_WIDTH,
  },
  {
    key: "phone",
    title: "Phone",
    className: "",
    minWidth: MIN_WIDTH,
  },
  {
    key: "address",
    title: "Address",
    className: "",
    minWidth: MIN_WIDTH,
  },
  {
    key: "city",
    title: "City",
    className: "",
    minWidth: "auto",
  },
  {
    key: "zip",
    title: "Zip",
    className: "",
    minWidth: "auto",
  },
  {
    key: "state",
    title: "State",
    className: "",
    minWidth: "auto",
  },

  {
    key: "slug",
    title: "Slug",
    className: "",
    minWidth: MIN_WIDTH,
  },
  {
    key: "w9",
    title: "W9",
    className: "",
    minWidth: MIN_WIDTH,
  },
  {
    key: "ach_enabled",
    title: "ACH Enabled",
    className: "",
    minWidth: "auto",
  },
  {
    key: "coming_soon",
    title: "Coming Soon",
    className: "",
    minWidth: "auto",
  },
  {
    key: "sboa",
    title: "SBOA",
    className: "",
    minWidth: "auto",
  },
  {
    key: "sboa_brochure",
    title: "SBOA Brochure",
    className: "",
    minWidth: MIN_WIDTH,
  },
  {
    key: "actions",
    title: "Actions",
    className: "text-center",
    minWidth: MIN_WIDTH,
  },
  {
    key: "cms_actions",
    title: "CMS",
    className: "text-center",
    minWidth: 60,
  },
];
