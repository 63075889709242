import React, { useState, useEffect } from "react";
import axios from "axios";
import { getLocationList } from "../../../utilities/common";
import FacilityMap from "../../../components/FacilityMap";
import { FaSearch } from "react-icons/fa";

function SnapShotMap(props) {
  const locationList = getLocationList();
  let {
    match: {
      params: { slug },
    },
  } = props;
  const location = locationList.find((location) => location.slug === slug);

  const [units, setUnits] = useState([]);
  const [floors, setFloors] = useState([]);
  const [visibleFloor, setVisibleFloor] = useState(null);
  const [activeUnits, setActiveUnits] = useState([]);

  useEffect(() => {
    if (!location) {
      location.href = "/"; // Unless location data was loaded,
      return;
    }
    loadLocationUnits();
  }, []);

  const loadLocationUnits = () => {
    axios
      .get(`/api/location_units?location_code=${location.location_code}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const units = res.data;
        setUnits(units);
        processUnits(units);
      });
  };

  const processUnits = (units) => {
    let floors = [];

    units.forEach((u) => {
      if (!floors.includes(u.floor_no)) {
        floors.push(u.floor_no);
      }
    });
    setFloors(floors);
    setVisibleFloor(floors[0]);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value.length < 3) {
      setActiveUnits([]);
      return;
    }

    const selected = units.filter((e) => e.unit_no?.includes(value)).map((e) => ({ unitNo: e.unit_no }));

    setActiveUnits(selected);
  };

  return (
    <div className="container-fluid my-4">
      <h1 className="text-center">Facility Map</h1>

      <div className="row justify-content-center">
        <div className="col-12 col-lg-6">
          <div className="form-group position-relative search-input">
            <FaSearch className=" position-absolute" />
            <div>
              <input onChange={handleSearch} type="text" className="form-control" placeholder="Search by unit number" />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10">
          <div className="text-right">
            {floors.map((f) => (
              <label key={f} className="mr-4">
                <input
                  type="radio"
                  className="mr-2"
                  checked={visibleFloor === f}
                  onChange={() => {}}
                  onClick={(e) => setVisibleFloor(f)}
                />
                Floor {f}
              </label>
            ))}
          </div>
          {units.length > 0 ? (
            <FacilityMap units={units} activeUnits={activeUnits} visibleFloor={visibleFloor} onMapClick={() => {}} />
          ) : (
            <p className="text-center">No location units imported.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SnapShotMap;
