import React from "react";

const Modal = ({ visible, setVisible, title, children, style }) => {
  const classShow = visible ? "show d-block" : "d-none";

  return (
    <div className={`modal fade ${classShow}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg shadow" style={style} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title justify-content-center">{title}</h5>
            <button
              onClick={() => setVisible(false)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
