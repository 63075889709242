import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Showdown from "showdown";
import axios from "axios";
import "./style.css";

const MAX_SMS_BODY_LENGTH = 160;

const CommunicationTemplatesEdit = (props) => {
  const [templateName, setTemplateName] = useState("");
  const [SMSBody, setSMSBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const fetchCommunicationTemplate = () => {
    const { id } = props.match.params;
    axios
      .get(`/api/communication_templates/${id}`)
      .then((res) => {
        const data = res.data;
        if (!data) {
          alert("Template not found");
          props.history.push("/communication-templates");
          return;
        }
        setTemplateName(data.template_name);
        setSMSBody(data.sms_body);
        setEmailSubject(data.email_subject);
        setEmailBody(data.email_body);
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    fetchCommunicationTemplate();
  }, []);

  const handleSubmit = () => {
    const { id } = props.match.params;
    axios
      .put(`/api/communication_templates/${id}`, {
        email_subject: emailSubject,
        email_body: emailBody,
        sms_body: SMSBody,
      })
      .then((res) => {
        alert("Communication Templates updated successfully.");
        props.history.push("/communication-templates");
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Edit Communication Template</h1>

      <div className="row py-2">
        <div className="col-4 text-right">Template Name</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input w-100" value={templateName} readOnly disabled />
        </div>
      </div>

      <div className="row py-2">
        <div className="col-4 text-right">SMS Body:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={SMSBody}
            onChange={(e) => (e.target.value.length <= MAX_SMS_BODY_LENGTH ? setSMSBody(e.target.value) : null)}
          />
          ({SMSBody.length} / {MAX_SMS_BODY_LENGTH})
        </div>
      </div>

      <div className="row py-2">
        <div className="col-4 text-right">Email Subject:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
          />
        </div>
      </div>

      <div className="row py-2">
        <div className="col-4 text-right">Email Body:</div>
        <div className="col-8 text-left">
          <textarea id="template-editor" rows="25" value={emailBody} onChange={(e) => setEmailBody(e.target.value)} />
        </div>
      </div>

      <div className="row py-2">
        <div className="col-4 text-right">Email Body Preview</div>
        <div className="col-8 text-left">
          <div className="email-preview-container">
            <div dangerouslySetInnerHTML={{ __html: emailBody }} />
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/communication-templates">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default CommunicationTemplatesEdit;
