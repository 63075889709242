import axios from "axios";
import { API_ENDPOINT } from "../utilities/constants";

const URL_API = {
  FETCH_ROLES: `${API_ENDPOINT.USERS}/roles`,
  CREATE_USER: `${API_ENDPOINT.USERS}`,
  DELETE_USER: `${API_ENDPOINT.USERS}`,
  UPDATE_USER: `${API_ENDPOINT.USERS}`,
};

// Get list roles
const fetchRoles = async () => {
  try {
    const response = await axios.get(URL_API.FETCH_ROLES);
    return response.data;
  } catch (error) {
    alert("Error occurred while fetching roles");
  }
};

// Create new user
const CREATE_USER_FAILED = "Error occurred while creating new user";
const createUser = async (data) => {
  try {
    const response = await axios.post(URL_API.CREATE_USER, data);
    return response;
  } catch (error) {
    const message = error.response.data.message || CREATE_USER_FAILED;
    alert(message);
  }
};

// Delete existing user
const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(URL_API.DELETE_USER, { params: { userId } });
    return response;
  } catch (error) {
    alert(error);
  }
};

const updateUser = async (data) => {
  try {
    const response = await axios.put(URL_API.UPDATE_USER, data);
    return response;
  } catch (error) {
    alert(error);
  }
};

export default { fetchRoles, createUser, deleteUser, updateUser };
