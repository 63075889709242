import React from "react";

function AuctionWinnerView(props) {
  const { data } = props;

  return (
    <>
      <div className="modal fade location-modal show" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Auction Winner Details</h4>
              <button type="button" className="close" aria-label="Close" onClick={props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-3">
              <table className="table table-hover table-bordered bg-white">
                <thead>
                  <tr>
                    <th>Property</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data).map((key) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{data[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button className="btn btn-indigo" onClick={props.onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
}

export default AuctionWinnerView;
