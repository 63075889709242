import React, { useState, useEffect } from "react";
import axios from "axios";

function GateCodeRotation() {
  const [gateCodes, setGateCodes] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDisplayWarning, setIsDisplayWarning] = useState(false);

  useEffect(() => {
    axios
      .get("/api/gate_code_rotation/gate_codes")
      .then((res) => {
        setGateCodes(
          res.data.reduce((prev, curr) => {
            return { ...prev, [curr.name]: curr };
          }, {})
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRotateCode = (name) => {
    setIsProcessing(true);
    setIsDisplayWarning(true);
    axios
      .post(
        name === "ALL" ? "/api/gate_code_rotation/rotateAll" : "/api/gate_code_rotation/rotate",
        name === "ALL" ? undefined : { rotate_name: name }
      )
      .then((res) => {
        window.alert(
          "Rotate successfully for: " + (name === "ALL" ? "Auction Winner, Emergency Code, Temp Code" : name)
        );
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <div className="container-fluid mt-4">
      <h1 className="text-center">Bulk Update Gate Codes</h1>
      <div className="row mt-4">
        <div className="col-md-6 d-flex justify-content-end align-items-center">
          Auction Winner:{" "}
          <button
            disabled={isProcessing}
            onClick={() => handleRotateCode("Auction Winner")}
            className="btn btn-primary ml-4"
          >
            Rotate code
          </button>
        </div>
        <div className="col-md-6 d-flex justify-content-start align-items-center">
          <p>
            Current Code: {gateCodes["Auction Winner"]?.currentCode} |{" "}
            <em>Last Update: {new Date(gateCodes["Auction Winner"]?.updatedAt).toLocaleString()}</em>
          </p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6 d-flex justify-content-end align-items-center">
          Emergency Code:{" "}
          <button
            disabled={isProcessing}
            onClick={() => handleRotateCode("Emergency Code")}
            className="btn btn-primary ml-4"
          >
            Rotate code
          </button>
        </div>
        <div className="col-md-6 d-flex justify-content-start align-items-center">
          <p>
            Current Code: {gateCodes["Emergency Code"]?.currentCode} |{" "}
            <em>Last Update: {new Date(gateCodes["Emergency Code"]?.updatedAt).toLocaleString()}</em>
          </p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6 d-flex justify-content-end align-items-center">
          Temp Code:{" "}
          <button
            disabled={isProcessing}
            onClick={() => handleRotateCode("Temp Code")}
            className="btn btn-primary ml-4"
          >
            Rotate code
          </button>
        </div>
        <div className="col-md-6 d-flex justify-content-start align-items-center">
          <p>
            Current Code: {gateCodes["Temp Code"]?.currentCode} |{" "}
            <em>Last Update: {new Date(gateCodes["Temp Code"]?.updatedAt).toLocaleString()}</em>
          </p>
        </div>
      </div>
      {isDisplayWarning && (
        <div className="mt-2 text-center text-danger">
          This should take a few minutes to update all facilities. Please wait…
        </div>
      )}
      <div className="row justify-content-center mt-4">
        <button
          disabled={isProcessing}
          onClick={() => handleRotateCode("ALL")}
          className="btn btn-primary ml-4 text-center"
        >
          Rotate All
        </button>
      </div>
    </div>
  );
}

export default GateCodeRotation;
