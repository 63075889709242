import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import * as Showdown from "showdown";

function QuestionEdit(props) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");

  const { id } = props.match.params;

  const fetchQuestion = () => {
    axios.get(`/api/questions/${id}`).then((res) => {
      const data = res.data;

      if (!data) {
        alert("Question not found");
        props.history.push("/faq");
        return;
      }

      setQuestion(data.question);
      setAnswer(data.answer);
    });
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  const handleSubmit = () => {
    const data = {
      question: question,
      answer: answer,
    };

    axios.put(`/api/questions/${id}`, data).then((res) => {
      if (res.status === 200) {
        alert("Successfully updated the contents!");
        props.history.push("/faq");
      } else {
        alert("Failed to update contents.");
      }
    });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Edit Question</h1>

      <div className="row py-2">
        <div className="col-4 text-right">Question:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Answer:</div>
        <div className="col-8 text-left">
          <ReactMde
            value={answer}
            onChange={setAnswer}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/faq">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default QuestionEdit;
