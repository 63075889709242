import React, { useState, useEffect } from "react";
import _ from "underscore";
import L from "leaflet";
import { MapContainer, Rectangle, Marker, useMapEvent, Popup, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const backgroundOptions = { color: "gray", fillColor: "white" };

const calcBackgroundRect = (units) => {
  let minX = Number.POSITIVE_INFINITY,
    minY = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY,
    maxY = Number.NEGATIVE_INFINITY;

  units.forEach((unit) => {
    if (minX > unit.left_coordinate) {
      minX = unit.left_coordinate;
    }

    if (minY > unit.top_coordinate) {
      minY = unit.top_coordinate;
    }

    if (maxX < unit.left_coordinate) {
      maxX = unit.left_coordinate;
    }

    if (maxY < unit.top_coordinate) {
      maxY = unit.top_coordinate;
    }
  });

  const delta = 80;

  return [
    [minY + delta, minX - delta],
    [-maxY - delta, maxX + delta],
  ];
};

function FacilityMap(props) {
  return (
    <MapContainer
      center={[-250, 160]}
      zoom={1}
      scrollWheelZoom={true}
      className="facility-map-container"
      attributionControl={false}
      crs={L.CRS.Simple}
      interactive={true}
      // zoomControl={false}
      // bounds={bounds}
      // onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
      // maxZoom={8}
      // minZoom={0}
      // boxZoom={false}
    >
      <MapComponents units={props.units} visibleFloor={props.visibleFloor} activeUnits={props.activeUnits} />
      <Rectangle bounds={calcBackgroundRect(props.units)} pathOptions={backgroundOptions} interactive={false} />
      <EventComponent onClick={props.onMapClick} interactive={props.interactive} />
    </MapContainer>
  );
}
function EventComponent(props) {
  const map = useMapEvents({
    click(e) {
      props.onClick(e.latlng);
      map.getContainer().style.cursor = "auto";
    },
  });

  useEffect(() => {
    if (props.interactive) {
      map.getContainer().style.cursor = "crosshair";
    } else {
      map.getContainer().style.cursor = "auto";
    }
  }, [props.interactive]);

  return false;
}

function MapComponents(props) {
  // const [selectedUnitId, setSelectedUnitId] = useState(0)
  const [zoomLevel, setZoomLevel] = useState(1);

  const activeUnitNumbers = (props.activeUnits || []).map((e) => e.unitNo);

  const map = useMapEvent("zoomend", () => {
    setZoomLevel(map.getZoom());
  });

  const renderSystemUnit = (unit) => {
    const [y, x] = [unit.top_coordinate, unit.left_coordinate];
    const [w, l] = [40, 40];

    let unitClass;

    if (unit.category_name === "Rental Center") {
      unitClass = "rental-center";
    }
    if (unit.category_name === "Gate") {
      unitClass = "gate";
    }

    const scale = ((unit.width || 10) / 20.0) * (zoomLevel + 1);
    const radian = (unit.system_rotation / 180.0) * Math.PI;
    const html = `<div
      class="system-unit-marker ${unitClass}"
      style="transform: rotate(${radian}rad) scale(${scale})"
    />`;
    var selectedIcon = L.divIcon({
      html: html,
      className: "",
      iconAnchor: [w / 2, l / 2],
    });

    return <Marker icon={selectedIcon} position={[-y - w / 2, x + l / 2]} interactive={false} />;
  };

  const renderUnit = (unit) => {
    if (unit.floor_no != props.visibleFloor) {
      return false;
    }
    if (unit.system) {
      return renderSystemUnit(unit);
    }

    const [y, x] = [unit.top_coordinate, unit.left_coordinate];
    const [w, l] = unit.rotate ? [unit.length, unit.width] : [unit.width, unit.length];

    const bounds = [
      [-y, x],
      [-y - w, x + l],
    ];

    // const highlighted = unit.unit_id == selectedUnitId;
    const highlighted = _.contains(activeUnitNumbers, unit.unit_no);

    const rectangleOptions = {
      color: highlighted ? "red" : "green",
      className: highlighted ? "highlighted-unit" : "",
    };

    const selectedIcon = L.divIcon({
      html: `<div>${unit.unit_no}</div>`,
      className: "unit-text-marker",
      iconAnchor: [0, 0],
    });

    const eventHandlers = {
      // click: (e) => {
      //   if (props.unitInteractive) {
      //     setSelectedUnitId(unit.unit_id)
      //   }
      // },
    };

    return (
      <React.Fragment key={unit.unit_id}>
        <Rectangle weight={1} bounds={bounds} pathOptions={rectangleOptions} interactive={false} />
        {zoomLevel > 2 && (
          <Marker
            icon={selectedIcon}
            position={[-y - w / 2, x + l / 2]}
            interactive={true}
            eventHandlers={eventHandlers}
          >
            <Popup>
              Unit No: {unit.unit_no}
              <br />
              {unit.category_name}
              <br />
              {unit.description}
            </Popup>
          </Marker>
        )}
      </React.Fragment>
    );
  };

  return props.units.map(renderUnit);
}

export default FacilityMap;
