import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../pages/NotFound";
import SnapShotPage from "../pages/SnapShot";
import SnapshotClosedTasks from "../pages/SnapShot/SnapshotClosedTasks";
import SnapshotFacilityTasks from "../pages/SnapShot/SnapshotFacilityTasks";
import SnapShotMap from "../pages/SnapShot/SnapShotMap";
import SnapshotNewTask from "../pages/SnapShot/SnapshotNewTask";
import SnapshotTaskDetails from "../pages/SnapShot/SnapshotTaskDetails";
import SnapshotTasksOverview from "../pages/SnapShot/SnapshotTasksOverview";
import SnapShotDetail from "../pages/SnapShotDetail";
import SnapShotLoginPage from "../pages/SnapShot/SnapshotLoginPage";
import { useSnapShotUser } from "../utilities/helper";

function SwitchPages() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={SnapShotPage} />
      <Route exact path="/login" component={SnapShotLoginPage} />
      <PrivateRoute exact path="/:slug" component={SnapShotDetail} />
      <PrivateRoute exact path="/:slug/map" component={SnapShotMap} />
      <PrivateRoute exact path="/snapshot/tasks-overview" component={SnapshotTasksOverview} />
      <PrivateRoute exact path="/snapshot/new-task/:site_code" component={SnapshotNewTask} />
      <PrivateRoute exact path="/snapshot/closed-task/:site_code" component={SnapshotClosedTasks} />
      <PrivateRoute exact path="/snapshot/task-details/:site_code/:task_id" component={SnapshotTaskDetails} />
      <PrivateRoute exact path="/snapshot/facility-tasks/:site_code" component={SnapshotFacilityTasks} />
      <Route component={NotFound} />
    </Switch>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  const { user, setUser } = useSnapShotUser();
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default SwitchPages;
