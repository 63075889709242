import { useEffect, useState } from "react";
import { BASE_URL_ENV, PAGE_SIZE } from "./constants";
import getAxiosWithDefaultHeaders from "../config";

export const useFetchSnapshotFacilityTasks = (site_code, closed, type) => {
  const [tasks, setTasks] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const Axios = getAxiosWithDefaultHeaders();

  useEffect(() => {
    const fetchSnapshotFacilityTasks = async () => {
      setIsLoading(true);

      const BASE_URL = `${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/tasks`;
      const url = new URL(BASE_URL);
      url.searchParams.set("page", currentPage);
      url.searchParams.set("page_size", PAGE_SIZE);
      url.searchParams.set("closed", closed);
      url.searchParams.set("location_code", site_code);
      // url.searchParams.set('latch', false)
      url.searchParams.set("type", type);

      try {
        const result = await Axios(url.toString());
        result.data.entries = result.data.entries.sort((a1, a2) => new Date(a2.created_at) - new Date(a1.created_at));
        setTasks(result.data.entries);
        setTotalItems(result.data.total_entries);
      } catch (error) {
        setTasks([]);
        setTotalItems(0);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSnapshotFacilityTasks();
  }, [currentPage, site_code]);

  return [isLoading, tasks, currentPage, setCurrentPage, totalItems];
};
