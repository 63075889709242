import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaDownload } from "react-icons/fa";

function Logs() {
  const location = useLocation();
  const isTenantUploadPage = location.pathname === "/tenant-rate-upload/logs";
  const [uploadLogs, setUploadLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchUploadLogs() {
      setIsLoading(true);
      const url = isTenantUploadPage ? "/api/tenant_rate/logs" : "/api/street_rate/logs";
      try {
        const result = await axios.get(url);
        setUploadLogs(result.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchUploadLogs();
  }, []);

  const downloadUploadLogs = async (uploadId) => {
    try {
      const url = isTenantUploadPage ? `/api/tenant_rate/logs/${uploadId}` : `/api/street_rate/logs/${uploadId}`;
      const result = await axios({
        url,
        method: "GET",
        responseType: "blob",
      });
      const href = URL.createObjectURL(result.data);
      const link = document.createElement("a");
      link.href = href;
      const fileName = isTenantUploadPage
        ? `tenant_rate_upload_id_${uploadId}.csv`
        : `street_rate_upload_id_${uploadId}.csv`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      window.alert("Failed to download csv file");
    }
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">{isTenantUploadPage ? "Tenant Rate Upload Logs" : "Street Rate Upload Logs"}</h1>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">{"#"}</th>
            <th scope="col">{"Upload time"}</th>
            <th scope="col">{"User name"}</th>
            <th scope="col">{"Number of records"}</th>
            <th scope="col">{"Actions"}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="5" className="text-center">
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          ) : null}
          {!isLoading && uploadLogs.length !== 0
            ? uploadLogs.map((log, index) => (
                <tr key={log.id}>
                  <td>{index + 1}</td>
                  <td>{new Date(log.uploaded_at).toLocaleString()}</td>
                  <td>{log.userName}</td>
                  <td>{log.numberOfRecords}</td>
                  <td>
                    <button
                      className="btn btn-outline-primary btn-sm"
                      style={{ minWidth: 40, width: 40 }}
                      title="Download log"
                      onClick={() => downloadUploadLogs(log.id)}
                    >
                      <FaDownload />
                    </button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}

export default Logs;
