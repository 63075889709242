import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import US_STATES from "../../utilities/us_states";

function CityCreate(props) {
  const [body, setBody] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [state, setState] = useState("");
  const [name, setName] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [imageAlt, setImageAlt] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleSubmit = () => {
    const data = {
      state: state,
      name: name,
      meta_title: metaTitle,
      meta_description: metaDescription,
      image_src: imageSrc.trim(),
      image_alt: imageAlt,
      body: body,
      lat: lat,
      long: long,
      zoom_level: zoomLevel,
    };

    axios.post(`/api/cities`, data).then((res) => {
      if (res.status === 200) {
        alert("Successfully created the city!");
      } else {
        alert("Failed to create city.");
      }
    });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Create City</h1>

      <div className="row py-2">
        <div className="col-4 text-right align-self-center">State:</div>
        <div className="col-8 text-left">
          <select className="form-control w-100" value={state} onChange={(e) => setState(e.target.value)}>
            {Object.keys(US_STATES).map((short_name) => (
              <option value={short_name} key={short_name}>
                {US_STATES[short_name]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">City Name:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-control w-100" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">Latitude:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-control w-100" value={lat} onChange={(e) => setLat(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">Longitude:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-control w-100" value={long} onChange={(e) => setLong(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">Zoom Level:</div>
        <div className="col-8 text-left">
          <input
            type="number"
            className="form-control"
            value={zoomLevel}
            onChange={(e) => setZoomLevel(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">Meta Title:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-control w-100"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">Meta Description:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-control w-100"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">Image Location:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-control w-100"
            value={imageSrc}
            onChange={(e) => setImageSrc(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">Image Alt:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-control w-100"
            value={imageAlt}
            onChange={(e) => setImageAlt(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right align-self-center">Body:</div>
        <div className="col-8 text-left">
          <ReactMde
            value={body}
            onChange={setBody}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/cities">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default CityCreate;
