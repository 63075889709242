import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import * as Showdown from "showdown";

function SettingEdit(props) {
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");

  const { id } = props.match.params;

  const fetchSetting = () => {
    axios.get(`/api/settings/${id}`).then((res) => {
      const data = res.data;

      if (!data) {
        alert("Setting not found");
        props.history.push("/settings");
        return;
      }

      setName(data.name);
      setValue(data.value);
      setDescription(data.description);
    });
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  const handleSubmit = () => {
    const data = {
      name,
      value,
      description,
    };

    axios.put(`/api/settings/${id}`, data).then((res) => {
      if (res.status === 200) {
        alert("Successfully updated the setting!");
        props.history.push("/settings");
      } else {
        alert("Failed to update setting.");
      }
    });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Edit Setting Content</h1>

      <div className="row py-2">
        <div className="col-4 text-right">Name:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={name}
            onChange={(e) => setName(e.target.value.toLowerCase())}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Value:</div>
        <div className="col-8 text-left">
          <ReactMde
            value={value}
            onChange={setValue}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Description:</div>
        <div className="col-8 text-left">
          <textarea className="form-input w-100" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/settings">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default SettingEdit;
