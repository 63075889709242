import React from "react";
import ReactDOM from "react-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import App from "./App";

const AZURE_REDIRECT_URIS = {
  development: "http://localhost:3000/azure",
  staging: "https://staging-admin.spareboxstorage.com/azure",
  production: "https://admin.spareboxstorage.com/azure",
};

// MSAL configuration
const configuration = {
  auth: {
    clientId: "6eb7aad3-d27c-42ad-8e3c-4dd53ceadb27",
    redirectUri: AZURE_REDIRECT_URIS[process.env.REACT_APP_ENV],
    authority: "https://login.microsoftonline.com/e0606da8-6cee-44bc-9a69-9115a4be901e",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
