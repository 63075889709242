import React from "react";

const Button = ({ title, onPress, typeBtn = "success", type = "button", className = "", tips = "", ...props }) => {
  return (
    <button title={tips} onClick={onPress} className={`btn btn-${typeBtn} ${className}`} type={type} {...props}>
      {title}
    </button>
  );
};

export default Button;
