import React from "react";
import { Link } from "react-router-dom";
import { PAGES_UNDER_ROLE, ROLES } from "../../utilities/constants";
import { useUser } from "../../utilities/helper";

const humanize = (str) => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
};

function Homepage() {
  const { user } = useUser();

  const roleName = humanize(user.role.name);

  return (
    <div className="wrapper">
      <h1 className="text-center mt-4">{`Internal website for ${roleName}!`}</h1>
      <div className="container">
        <ul>
          {PAGES_UNDER_ROLE.map((e) =>
            user.role.superadmin || user.role.allowed_pages.includes(e.path) ? (
              <li key={"link_" + e.path}>
                <Link to={e.path}>{e.name}</Link>
              </li>
            ) : null
          )}
          {user.role.superadmin && (
            <li>
              <Link to="/roles">User Roles</Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Homepage;
