import React, { useEffect, useState } from "react";
import { Input, DropDown, Button } from "../../components";
import UserService from "../../services/users";

const UserForm = ({ setUsers, editData, onCancelEdit, onCloseModal }) => {
  const [roles, setRoles] = useState([]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");

  const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    if (editData) {
      setEmail(editData.email);
      setFirstName(editData.firstName);
      setLastName(editData.lastName);
      const role = roles.find((item) => item.value == editData.role_id);
      console.log(roles, editData, role);
      setSelectedRole(role.value);
    } else {
      setInitialState();
    }
  }, [editData]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    const data = await UserService.fetchRoles();
    const newRoles = data.map((role) => ({
      value: role.id,
      title: role.name,
    }));
    setSelectedRole(newRoles[0].value);
    setRoles(newRoles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
      firstName,
      lastName,
      role_id: selectedRole,
    };

    if (editData) {
      if (!email) {
        return alert("Email can't be blank.");
      }
      data.id = editData.id;
      const response = await UserService.updateUser(data);

      if (response.status === 200) {
        setUsers((users) =>
          users.map((user) => {
            if (user.id === editData.id) {
              return { ...data, role: editData.role };
            }
            return user;
          })
        );
        alert("Account updated successfully");
        onCloseModal();
      }
    } else {
      if (!email || !password) {
        return alert("Please fill in all the required fields.");
      }
      const response = await UserService.createUser(data);
      if (response) {
        setUsers((users) => [response.data, ...users]);
        alert("Account created successfully");
        onCloseModal();
      }
    }
    setInitialState();
  };

  const setInitialState = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setPassword("");
  };

  return (
    <form className="p-2" onSubmit={handleSubmit}>
      <div className="col-12">
        <Input
          title="Email"
          value={email}
          type="email"
          onChange={(e) => {
            if (!editData) {
              setEmail(e.target.value);
            }
          }}
        />
      </div>
      <div className="col-12">
        <Input title="Password" type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
      </div>
      <div className="col-12">
        <Input title="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
      </div>
      <div className="col-12">
        <Input title="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </div>
      <div className="col-12">
        <DropDown title="Role" options={roles} value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)} />
      </div>
      <div className="col-12 d-flex justify-content-end">
        {editData ? (
          <div className="row">
            <div className="mx-2">
              <Button title="Update" type="submit" />
            </div>
            <div className="mx-2">
              <Button
                title="Cancel"
                type="button"
                typeBtn="dark"
                onPress={() => {
                  setInitialState();
                  onCancelEdit();
                }}
              />
            </div>
          </div>
        ) : (
          <Button title="Create" type="submit" />
        )}
      </div>
    </form>
  );
};

export default UserForm;
