import axios from "axios";
import { API_ENDPOINT } from "../utilities/constants";
import API from "./api";

export const SEARCH_TYPE = {
  SMS: "sms",
  EMAIL: "email",
};

const URL_API = {
  SEARCH_TENANTS: `${API_ENDPOINT.DELINQUENT_TENANTS}/search`,
  GET_UNITS_BY_TENANT_ID: `${API_ENDPOINT.DELINQUENT_TENANTS}/units`,
  SEND_PARTIAL_LINK: `${API_ENDPOINT.DELINQUENT_TENANTS}/send-link`,
};

const searchTenants = ({ type, query }) => {
  const params = {
    type,
    query,
  };
  return API.get(URL_API.SEARCH_TENANTS, {
    params,
  });
};

const getUnitByTenantId = (id) => {
  const params = { id };
  return API.get(URL_API.GET_UNITS_BY_TENANT_ID, { params });
};

const sendPartialLink = (data) => {
  return API.post(URL_API.SEND_PARTIAL_LINK, data).then(() => alert("Link sent successfully"));
};
export default { searchTenants, getUnitByTenantId, sendPartialLink };
