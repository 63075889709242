import Axios from "axios";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Input } from "../../../components";
import { useSnapShotUser } from "../../../utilities/helper";

function SnapShotLoginPage() {
  const { user, setUser } = useSnapShotUser();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const onSubmit = (e) => {
    e.preventDefault();
    if (userName && password) {
      setIsSubmitting(true);
      const data = {
        service_name: "ValidateSSMLogin",
        xml_body: { LoginKeyed: userName, passwordKeyed: password },
      };
      Axios.post("/api/ssm", data)
        .then((result) => {
          if (result.data.UserDetails?.ErrorCode?.[0]) {
            throw new Error(result.data.UserDetails.ErrorMessage[0]);
          }
          setUser(result.data.UserDetails);
          const { from } = location.state || { from: { pathname: "/" } };
          history.replace(from);
        })
        .catch((error) => {
          setError(error.toString());
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      alert("Please enter a valid username and password");
    }
  };

  const onInputChange = (e, cb) => {
    const { value } = e.target;
    return cb(value);
  };

  return (
    <>
      <Helmet>
        <title>Snapshot</title>
      </Helmet>
      <div className="container mt-4">
        {error && <div className="alert alert-danger">{error}</div>}
        <form className="col-6 mx-auto mt-3" onSubmit={onSubmit}>
          <Input title="Username" type="text" onChange={(e) => onInputChange(e, setUserName)} />
          <Input
            type="password"
            title="Password"
            autoComplete="password"
            onChange={(e) => onInputChange(e, setPassword)}
          />
          <div className="row justify-content-center">
            <Button type="submit" title="Login" className="mr-4" disabled={isSubmitting} />
          </div>
        </form>
      </div>
    </>
  );
}

export default SnapShotLoginPage;
