import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
const BreadCrumb = ({ title = "", path = "" }) => {
  return (
    <Link to={path}>
      <MdKeyboardArrowLeft size={20} />
      {title}
    </Link>
  );
};

export default BreadCrumb;
