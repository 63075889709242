import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function AuctionWinnerUpload() {
  const [message, setMessage] = useState("");

  const handleSubmitCSV = (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);

    setMessage("Importing CSV ...");
    axios
      .post("/api/auction_winners/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          sendEmails();
          setMessage("Import Finished.");
        }
      });
  };

  const sendEmails = () => {
    const confirmed = window.confirm(
      "Auction winners are now imported. Do you want to send them auction winner emails?"
    );
    if (!confirmed) return;
    setMessage("Sending out emails.");

    axios.post(`/api/auction_winners/send_emails`).then((result) => {
      setMessage("Finished.");
    });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Auction Winner Upload Page</h1>

      <div className="row">
        <div className="col-12 text-center">
          <section className="section-preview" style={{ width: 400, display: "inline-block" }}>
            <div className="input-group my-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Upload</span>
              </div>
              <div className="custom-file">
                <input type="file" className="custom-file-input" accept=".csv" onChange={handleSubmitCSV} />
                <label className="custom-file-label">Choose CSV File</label>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center text-danger">{message}</div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <Link to="/auctions/history"> Show Full List of Imported Auction Winners</Link>
        </div>
      </div>
    </div>
  );
}

export default AuctionWinnerUpload;
