import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LOCATION_FIELDS } from "./constants";
import { FaMinusCircle } from "react-icons/fa";

function LocationCreate(props) {
  const initialValues = getInitialValues(props);
  return (
    <>
      <div className="modal fade location-modal show" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              const { location, mode } = props;
              if (mode === "create") {
                axios.post("/api/locations", values).then((res) => {
                  if (res.status === 200) {
                    props.onFinish();
                    props.onClose();
                  }
                });
              } else if (mode === "edit") {
                axios.put(`/api/locations/${location.id}`, values).then((res) => {
                  if (res.status === 200) {
                    props.onFinish();
                    props.onClose();
                  }
                });
              }
            }}
          >
            {({ values, setFieldValue, handleChange }) => (
              <Form>
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <h4 className="modal-title w-100 font-weight-bold">
                      {props.mode === "create" ? "New Location" : "Edit Location Details"}
                    </h4>
                    <button type="button" className="close" aria-label="Close" onClick={props.onClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body mx-3">
                    <table className="table table-hover table-bordered bg-white">
                      <thead>
                        <tr>
                          <th>Property</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(LOCATION_FIELDS).map((key) => (
                          <tr key={key}>
                            <td>{LOCATION_FIELDS[key].label || key}</td>
                            <td>
                              {renderField(key, values, setFieldValue, handleChange)}
                              <ErrorMessage name={key} render={(msg) => <div className="text-danger">{msg}</div>} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer d-flex justify-content-end">
                    <button className="btn btn-indigo" onClick={props.onClose}>
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
}

LocationCreate.propTypes = {
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  mode: PropTypes.oneOf(["edit", "create"]),
  location: PropTypes.object,
};

function getInitialValues(props) {
  let initialValues = {};

  for (const key of Object.keys(LOCATION_FIELDS)) {
    initialValues[key] = LOCATION_FIELDS[key].value;
  }

  initialValues = {
    ...initialValues,
    ...(props.location || {}),
  };

  delete initialValues.id;
  delete initialValues.createdAt;
  delete initialValues.updatedAt;

  // strip null value to default value
  for (const key of Object.keys(initialValues)) {
    if (initialValues[key] === null) {
      initialValues[key] = LOCATION_FIELDS[key].value;
    }
  }
  return initialValues;
}

const isFieldDisabled = (key, values) => {
  if (key === "sboa_brochure" && values.sboa === false) {
    return true;
  }
  return false;
};

const customOnChange = (event, setFieldValue, handleChange) => {
  if (event.target.name === "sboa" && !event.target.checked) {
    setFieldValue("sboa_brochure", "", true);
    handleChange(event);
    return;
  }
  handleChange(event);
};

const renderField = (key, values, setFieldValue, handleChange) => {
  if (LOCATION_FIELDS[key].type === "array") {
    return (
      <>
        {values[key].map((value, index) => (
          <div key={index} className="d-flex justify-content-between">
            <Field
              key={`${key}-${index}`}
              className="form-input mb-3"
              name={key}
              type={"text"}
              value={value}
              onChange={(event) => onItemChange(event, setFieldValue, index, values[key])}
            />
            <span
              className="ml-3 cursor-pointer action-btn text-danger"
              title="Delete Keyword"
              onClick={() => onRemoveItem(key, setFieldValue, index, values[key])}
            >
              <FaMinusCircle />
            </span>
          </div>
        ))}
        <button type="button" className="btn btn-primary" onClick={() => onAddItem(key, setFieldValue, values[key])}>
          Add New Item
        </button>
      </>
    );
  }
  return (
    <Field
      type={!LOCATION_FIELDS[key].type ? "text" : LOCATION_FIELDS[key].type}
      name={key}
      disabled={isFieldDisabled(key, values)}
      onChange={(event) => customOnChange(event, setFieldValue, handleChange)}
    />
  );
};

const onItemChange = (event, setFieldValue, index, currentValue) => {
  const cloneCurrentValue = [...currentValue];
  cloneCurrentValue[index] = event.target.value;
  setFieldValue(event.target.name, cloneCurrentValue, true);
};

const onAddItem = (key, setFieldValue, currentValue) => {
  const cloneCurrentValue = [...currentValue];
  cloneCurrentValue.push("");
  setFieldValue(key, cloneCurrentValue, true);
};

const onRemoveItem = (key, setFieldValue, index, currentValue) => {
  const cloneCurrentValue = [...currentValue];
  cloneCurrentValue.splice(index, 1);
  setFieldValue(key, cloneCurrentValue, true);
};

export default LocationCreate;
