import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaTrash, FaPlus, FaTools } from "react-icons/fa";
import { useUser } from "../../utilities/helper";
import { ROLES } from "../../utilities/constants";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const { user } = useUser();
  const fetchBlogs = () => {
    axios.get("/api/blogs").then((res) => {
      setBlogs(res.data || []);
    });
  };

  const handleDeleteBlog = (id) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      axios.delete("/api/blogs/" + id).then((res) => {
        if (res.status === 200) {
          fetchBlogs();
        }
      });
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div className="container-fluid mt-2">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-4">
                <h2>
                  <b>Blog</b>
                </h2>
              </div>
              <div className="col-sm-8">
                <Link className="btn btn-info add-new float-right" title="New Blog" to={`/blogs/new`}>
                  <FaPlus /> New Blog
                </Link>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th className="text-center" style={{ width: "50px" }}>
                  ID
                </th>
                <th>Title</th>
                <th className="text-center" style={{ width: "120px" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((blog, index) => (
                <tr key={"s_" + blog.id}>
                  <td className="text-center">{blog.id}</td>
                  <td className="">{blog.title}</td>
                  <td className="text-center actions">
                    <span
                      className="action-btn text-danger px-1"
                      title="Delete"
                      onClick={() => handleDeleteBlog(blog.id)}
                    >
                      <FaTrash />
                    </span>
                    <Link className="action-btn text-success px-1" title="Edit CMS Content" to={`/blogs/${blog.id}`}>
                      <FaTools />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
