import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import { FaMinusCircle } from "react-icons/fa";

function StateEdit(props) {
  const [keywords, setKeywords] = useState([]);

  const [body, setBody] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [fullName, setFullName] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [imageAlt, setImageAlt] = useState("");
  const [mapImg, setMapImg] = useState("");
  const [leaseTemplateUrl, setLeaseTemplateUrl] = useState("");
  const [sboaAddendum, setSboaAddendum] = useState("");
  const [sboaAdditionalDocuments, setSboaAdditionalDocuments] = useState([]);
  const [generalContactNumber, setGeneralContactNumber] = useState("");

  const { short_name } = props.match.params;

  const fetchState = () => {
    axios.get(`/api/states/${short_name}`).then((res) => {
      const stateContent = res.data;

      if (!stateContent) {
        alert("State code is not valid.");
        props.history.push("/states");
        return;
      }

      setBody(stateContent.body || "");
      setKeywords(stateContent.keywords || []);
      setMetaTitle(stateContent.meta_title || "");
      setMetaDescription(stateContent.meta_description || "");
      setImageSrc(stateContent.image_src || "");
      setImageAlt(stateContent.image_alt || "");
      setLat(stateContent.lat || "");
      setLong(stateContent.long || "");
      setZoomLevel(stateContent.zoom_level || 1);
      setFullName(stateContent.full_name || "");
      setMapImg(stateContent.map_img || "");
      setSboaAddendum(stateContent.sboa_addendum || "");
      setSboaAdditionalDocuments(stateContent.sboa_additional_documents || []);
      setLeaseTemplateUrl(stateContent.lease_template_url || "");
      setGeneralContactNumber(stateContent.general_contact_number || "");
    });
  };

  useEffect(() => {
    fetchState();
  }, []);

  const newKeyword = () => {
    setKeywords(keywords.concat(""));
  };

  const changeKeyword = (index, value) => {
    keywords[index] = value;
    setKeywords([...keywords]);
  };

  const removeKeyword = (index) => {
    keywords.splice(index, 1);
    setKeywords([...keywords]);
  };

  const newSboaAdditionalDocument = () => {
    setSboaAdditionalDocuments(sboaAdditionalDocuments.concat(""));
  };

  const changeSboaDocument = (index, value) => {
    sboaAdditionalDocuments[index] = value;
    setSboaAdditionalDocuments([...sboaAdditionalDocuments]);
  };

  const removeSBoaDocument = (index) => {
    sboaAdditionalDocuments.splice(index, 1);
    setSboaAdditionalDocuments([...sboaAdditionalDocuments]);
  };

  const handleSubmit = () => {
    const data = {
      meta_title: metaTitle,
      meta_description: metaDescription,
      map_img: mapImg.trim(),
      image_src: imageSrc.trim(),
      image_alt: imageAlt,
      keywords: keywords,
      body: body,
      lat: lat,
      long: long,
      zoom_level: zoomLevel,
      lease_template_url: leaseTemplateUrl,
      sboa_addendum: sboaAddendum,
      sboa_additional_documents: sboaAdditionalDocuments,
      general_contact_number: generalContactNumber,
    };

    axios.put(`/api/states/${short_name}`, data).then((res) => {
      if (res.status === 200) {
        alert("Successfully updated the contents!");
      } else {
        alert("Failed to update contents.");
      }
    });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Edit State Content</h1>

      <div className="row py-2">
        <div className="col-4 text-right">Short Name:</div>
        <div className="col-8 text-left">
          <input type="text" disabled={true} defaultValue={short_name} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">State Name:</div>
        <div className="col-8 text-left">
          <input type="text" disabled={true} defaultValue={fullName} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Latitude:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input" value={lat} onChange={(e) => setLat(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Longitude:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input" value={long} onChange={(e) => setLong(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Zoom Level:</div>
        <div className="col-8 text-left">
          <input
            type="number"
            className="form-input"
            value={zoomLevel}
            onChange={(e) => setZoomLevel(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Meta Title:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Meta Description:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Keywords (Deprecated):</div>
        <div className="col-8 text-left">
          {keywords.map((keyword, index) => (
            <div key={`keyword${index}`}>
              <input
                type="text"
                className="form-input mb-1 w-75"
                value={keyword}
                onChange={(e) => changeKeyword(index, e.target.value)}
              />
              <span
                className="ml-3 cursor-pointer action-btn text-danger"
                title="Delete Keyword"
                onClick={() => removeKeyword(index)}
              >
                <FaMinusCircle />
              </span>
            </div>
          ))}
          <button className="btn btn-primary" onClick={newKeyword}>
            Add New Keyword
          </button>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">State Map Image Location:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input w-100" value={mapImg} onChange={(e) => setMapImg(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Image Location:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={imageSrc}
            onChange={(e) => setImageSrc(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Image Alt:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={imageAlt}
            onChange={(e) => setImageAlt(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Body:</div>
        <div className="col-8 text-left">
          <ReactMde
            value={body}
            onChange={setBody}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">SBOA addendum:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={sboaAddendum}
            onChange={(e) => setSboaAddendum(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">SBOA additional documents:</div>
        <div className="col-8 text-left">
          {sboaAdditionalDocuments.map((document, index) => (
            <div key={`sboa_document${index}`}>
              <input
                type="text"
                className="form-input mb-1 w-75"
                value={document}
                onChange={(e) => changeSboaDocument(index, e.target.value)}
              />
              <span
                className="ml-3 cursor-pointer action-btn text-danger"
                title="Delete SBOA additional document"
                onClick={() => removeSBoaDocument(index)}
              >
                <FaMinusCircle />
              </span>
            </div>
          ))}
          <button className="btn btn-primary mt-2" onClick={newSboaAdditionalDocument}>
            Add new SBOA additional document
          </button>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Lease template URL:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={leaseTemplateUrl}
            onChange={(e) => setLeaseTemplateUrl(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">General contact number:</div>
        <div className="col-8 text-left">
          <input
            type="tel"
            className="form-input w-100"
            value={generalContactNumber}
            onChange={(e) => setGeneralContactNumber(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/states">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default StateEdit;
