import axios from "axios";

// Get list marketing_features
const FETCH_ROLE_FAILED = "Error occurred while fetching marketing features";
const fetchMarketingFeatures = async () => {
  try {
    const response = await axios.get("api/marketing_features");
    return response.data;
  } catch (error) {
    const message = error.response.data.message || FETCH_ROLE_FAILED;
    alert(message);
  }
};

// Create new marketing_feature
const CREATE_ROLE_FAILED = "Error occurred while creating new marketing feature";
const createMarketingFeature = async (data) => {
  try {
    const response = await axios.post("api/marketing_features", data);
    return response;
  } catch (error) {
    const message = error.response.data.message || CREATE_ROLE_FAILED;
    alert(message);
  }
};

// Delete existing marketing_feature
const deleteMarketingFeature = async (marketing_featureId) => {
  try {
    const response = await axios.delete("api/marketing_features", { params: { marketing_featureId } });
    return response;
  } catch (error) {
    alert(error);
  }
};

const updateMarketingFeature = async (data) => {
  try {
    const response = await axios.put("api/marketing_features/" + data.id, data);
    return response;
  } catch (error) {
    alert(error);
  }
};

export default { fetchMarketingFeatures, createMarketingFeature, deleteMarketingFeature, updateMarketingFeature };
