import Axios from "axios";
import { KEY_SNAPSHOT_USER } from "../../utilities/constants";

const getAxiosWithDefaultHeaders = () => {
  const snapshotUserInfo = JSON.parse(localStorage.getItem(KEY_SNAPSHOT_USER));
  const instance = Axios.create();
  instance.defaults.headers.common["SnapshotUsername"] = snapshotUserInfo?.Login?.[0];
  return instance;
};

export default getAxiosWithDefaultHeaders;
