import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrash, FaPen } from "react-icons/fa";
import MarketingFeatureService from "../../services/marketing_features";
import { Button, Modal } from "../../components";
import MarketingFeatureForm from "./MarketingFeatureForm";

function MarketingFeatures() {
  const [marketing_features, setMarketingFeatures] = useState([]);
  const [editData, setEditData] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  const fetchMarketingFeatures = () => {
    axios.get("/api/marketing_features").then((res) => {
      setMarketingFeatures(res.data || []);
    });
  };

  const closeModal = () => {
    setEditData(null);
    setVisibleModal(false);
  };

  const handleDeleteMarketingFeature = async (id) => {
    if (window.confirm("Are you sure you want to delete this feature?")) {
      MarketingFeatureService;
      axios.delete("/api/marketing_features/" + id).then((res) => {
        if (res.status === 200) {
          fetchMarketingFeatures();
        }
      });
    }
  };

  useEffect(() => {
    fetchMarketingFeatures();
  }, []);

  return (
    <div className="container-fluid mt-2">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <h2 className="text-center">
              <b>Marketing Features</b>
            </h2>
            <Button
              title="Create Marketing Feature"
              className="float-right mb-4"
              onPress={() => {
                setVisibleModal(true);
                setEditData(null);
              }}
            />
          </div>
          <Modal
            visible={visibleModal}
            setVisible={closeModal}
            title={editData ? "Edit Marketing Feature" : "Create Marketing Feature"}
          >
            <MarketingFeatureForm
              editData={editData}
              onClose={() => closeModal()}
              onFinish={() => fetchMarketingFeatures()}
            />
          </Modal>

          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th className="text-center" style={{ width: "80px" }}>
                  ID
                </th>
                <th>Name</th>
                <th className="text-center" style={{ width: "120px" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {marketing_features.map((marketing_feature, index) => (
                <tr key={"s_" + marketing_feature.id}>
                  <td className="text-center">{marketing_feature.id}</td>
                  <td className="">{marketing_feature.name}</td>
                  <td className="text-center actions">
                    <span
                      className="btn text-warning px-1"
                      title="Edit"
                      onClick={() => {
                        setEditData(marketing_feature);
                        setVisibleModal(true);
                      }}
                    >
                      <FaPen />
                    </span>
                    <span
                      className="action-btn text-danger px-1"
                      title="Delete"
                      onClick={() => handleDeleteMarketingFeature(marketing_feature.id)}
                    >
                      <FaTrash />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MarketingFeatures;
