import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import * as Showdown from "showdown";

function PageCreate(props) {
  const editMode = props.match.path === "/pages/edit/:id" && props.match.params.id ? true : false;

  const pageId = props.match.params?.id;
  const [pageContent, setPageContent] = useState("");
  const [pageName, setPageName] = useState("");
  const [title, setTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");

  useEffect(() => {
    if (editMode) {
      axios
        .get(`/api/pages/${pageId}`)
        .then((res) => {
          setPageContent(res?.data?.content || "");
          setPageName(res?.data?.name || "");
          setTitle(res?.data?.title || "");
          setMetaDescription(res?.data?.meta_description || "");
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [editMode]);

  const handleSubmit = () => {
    const data = {
      id: pageId,
      name: pageName,
      content: pageContent,
      title: title,
      meta_description: metaDescription,
    };

    axios.post(`/api/pages`, data).then((res) => {
      if (res.status === 200) {
        alert(`Successfully ${editMode ? "edit" : "create"} a page!`);
        props.history.push("/pages");
      } else {
        alert("Failed to create page.");
      }
    });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">{editMode ? "Edit Page" : "Create Page"}</h1>

      <div className="row py-2">
        <div className="col-2 text-right">Page Name:</div>
        <div className="col-10 text-left">
          <input
            type="text"
            disabled={editMode}
            className="form-input w-100"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-2 text-right">Meta Title:</div>
        <div className="col-10 text-left">
          <input type="text" className="form-input w-100" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-2 text-right">Meta Description:</div>
        <div className="col-10 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-2 text-right">Page Content:</div>
        <div className="col-10 text-left">
          <ReactMde
            minEditorHeight={400}
            minPreviewHeight={400}
            value={pageContent}
            onChange={setPageContent}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/pages">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default PageCreate;
