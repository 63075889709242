import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import { FaMinusCircle } from "react-icons/fa";
import * as Showdown from "showdown";

function BlogEdit(props) {
  const [body, setBody] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");

  const [keywords, setKeywords] = useState([]);
  const [title, setTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [blogImageAlt, setBlogImageAlt] = useState("");

  const newKeyword = () => {
    setKeywords(keywords.concat(""));
  };

  const changeKeyword = (index, value) => {
    keywords[index] = value;
    setKeywords([...keywords]);
  };

  const removeKeyword = (index) => {
    keywords.splice(index, 1);
    setKeywords([...keywords]);
  };

  const { id } = props.match.params;
  const fetchBlog = () => {
    axios.get(`/api/blogs/${id}`).then((res) => {
      const data = res.data;

      if (!data) {
        alert("Blog not found");
        props.history.push("/blogs");
        return;
      }
      setBody(data.body);
      setKeywords(data.keywords || []);
      setTitle(data.title);
      setMetaTitle(data.meta_title);
      setMetaDescription(data.meta_description);
      setBlogImage(data.blog_image);
      setBlogImageAlt(data.blog_image_alt);
    });
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const handleSubmit = () => {
    const data = {
      title,
      meta_title: metaTitle,
      meta_description: metaDescription,
      keywords: keywords,
      blog_image: blogImage,
      blog_image_alt: blogImageAlt,
      body: body,
    };

    axios.put(`/api/blogs/${id}`, data).then((res) => {
      if (res.status === 200) {
        alert("Successfully updated the blog!");
        props.history.push("/blogs");
      } else {
        alert("Failed to create contents.");
      }
    });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Edit Blog</h1>

      <div className="row py-2">
        <div className="col-4 text-right">Title:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input w-100" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Meta Title:</div>
        <div className="col-8 text-left">
          <textarea className="form-input w-100" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Meta Description:</div>
        <div className="col-8 text-left">
          <textarea
            className="form-input w-100"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Keywords (Deprecated):</div>
        <div className="col-8 text-left">
          {keywords.map((keyword, index) => (
            <div key={"keyword" + index}>
              <input
                type="text"
                className="form-input mb-1 w-75"
                value={keyword}
                onChange={(e) => changeKeyword(index, e.target.value)}
              />
              <span
                className="ml-3 cursor-pointer action-btn text-danger"
                title="Delete Keyword"
                onClick={() => removeKeyword(index)}
              >
                <FaMinusCircle />
              </span>
            </div>
          ))}
          <button className="btn btn-primary" onClick={newKeyword}>
            Add New Keyword
          </button>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Blog Image Location:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={blogImage}
            onChange={(e) => setBlogImage(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Blog Image Alt:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={blogImageAlt}
            onChange={(e) => setBlogImageAlt(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Body:</div>
        <div className="col-8 text-left">
          <ReactMde
            value={body}
            onChange={setBody}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/blogs">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default BlogEdit;
