import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import axios from "axios";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Link } from "react-router-dom";

function Auctions() {
  const [completedAuctions, setCompletedAuctions] = useState([]);
  const [activeAuction, setActiveAuction] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [gateCode, setGateCode] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoadingCompletedAuctions, setIsLoadingCompletedAuctions] = useState(true);
  const [isLoadingActiveAuction, setIsLoadingActiveAuction] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    axios
      .get("/api/auction/active_auction")
      .then((res) => {
        setActiveAuction(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingActiveAuction(false);
      });

    axios
      .get("/api/auction/completed_auctions")
      .then(async (res) => {
        try {
          const auctions = [...res.data];
          for (let auction of auctions) {
            const result = await axios.get(`/api/auction/${auction.id}/count_auction_winners`);
            auction.numberOfAuctionWinners = result.data.count;
          }
          setCompletedAuctions(auctions);
        } catch (error) {
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingCompletedAuctions(false);
      });
  }, [reload]);

  const resetModal = () => {
    setStartDate(null);
    setEndDate(null);
    setGateCode("");
  };

  const saveAuction = () => {
    setIsProcessing(true);
    const data = {
      startDate: new Date(startDate).setHours(0, 0, 0, 0),
      endDate: new Date(endDate).setHours(23, 59, 59, 999),
      gateCode,
    };

    axios
      .post(`/api/auction`, data)
      .then((res) => {
        if (res.status === 200) {
          alert("Successfully created auction!");
          setReload(!reload);
          document.getElementById("btn_close").click();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error.response.data.message);
          return;
        }
        alert("Failed to create auction.");
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row mb-3">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button type="button" className="btn btn-info add-new mx-2" data-toggle="modal" data-target="#exampleModal">
            Create new auction
          </button>
          <Link to="/auctions/history" className="btn btn-success add-new mx-2">
            Imported Auction Winners
          </Link>
          <Link to="/auctions/new-upload" className="btn btn-dark add-new mx-2">
            Upload Auction Winner CSV
          </Link>
        </div>
      </div>
      <div className="table-title">
        <h2>
          <b>Active Auction</b>
        </h2>
      </div>
      <div className="table-responsive mb-3">
        <div className="table-wrapper">
          <table className="table table-striped table-hover table-bordered bg-white custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Start date</th>
                <th>End date</th>
                <th style={{ width: 400 }}>Auction gate code</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingActiveAuction ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : null}
              {!isLoadingActiveAuction && !activeAuction && (
                <tr>
                  <td colSpan={4} className="text-center">
                    No active auction found.
                  </td>
                </tr>
              )}
              {!isLoadingActiveAuction && activeAuction && (
                <tr>
                  <td className="text-center">{activeAuction.id}</td>
                  <td>{new Date(activeAuction.startDate).toLocaleDateString("en-US")}</td>
                  <td>{new Date(activeAuction.endDate).toLocaleDateString("en-US")}</td>
                  <td>{activeAuction.gateCode}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="table-title">
        <h2>
          <b>Completed Auctions</b>
        </h2>
      </div>
      <div className="table-responsive">
        <div className="table-wrapper">
          <table className="table table-striped table-hover table-bordered bg-white custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Start date</th>
                <th>End date</th>
                <th style={{ width: 400 }}>Number of auction winners</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingCompletedAuctions ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : null}
              {!isLoadingCompletedAuctions &&
                completedAuctions.map((auction) => (
                  <tr key={auction.id}>
                    <td className="text-center">{auction.id}</td>
                    <td>{new Date(auction.startDate).toLocaleDateString("en-US")}</td>
                    <td>{new Date(auction.endDate).toLocaleDateString("en-US")}</td>
                    <td>{auction.numberOfAuctionWinners}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create New Auction
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={resetModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>Select start date - end date:</label>
                <DateRangePicker
                  startDate={startDate}
                  startDateId="start_date_id"
                  endDate={endDate}
                  endDateId="end_date_id"
                  displayFormat="MM/DD/YYYY"
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  showClearDates
                  regular
                  showDefaultInputIcon
                />
              </div>
              <div className="form-group">
                <label htmlFor="gateCode">Gate Code:</label>
                <input
                  type="text"
                  placeholder="*xxxxx#"
                  className="form-control"
                  name="gateCode"
                  id="gateCode"
                  value={gateCode}
                  onChange={(e) => setGateCode(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={resetModal}
                id="btn_close"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveAuction}
                disabled={isProcessing || !startDate || !endDate || !gateCode}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auctions;
