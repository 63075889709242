import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaTrash, FaPlus, FaTools } from "react-icons/fa";
import { useUser } from "../../utilities/helper";
import { ROLES } from "../../utilities/constants";

function FAQ() {
  const [questions, setQuestions] = useState([]);
  const [libraries, setLibraries] = useState([]);
  const { user } = useUser();

  const fetchQuestions = () => {
    axios.get("/api/questions").then((res) => {
      setQuestions(res.data || []);
    });
  };
  const fetchLibraries = () => {
    axios.get("/api/libraries").then((res) => {
      setLibraries(res.data || []);
    });
  };

  const handleDeleteQuestion = (id) => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      axios.delete("/api/questions/" + id).then((res) => {
        if (res.status === 200) {
          fetchQuestions();
        }
      });
    }
  };

  const handleDeleteLibrary = (id) => {
    if (window.confirm("Are you sure you want to delete this library?")) {
      axios.delete("/api/libraries/" + id).then((res) => {
        if (res.status === 200) {
          fetchLibraries();
        }
      });
    }
  };

  useEffect(() => {
    fetchQuestions();
    fetchLibraries();
  }, []);

  const authorized = user.role.superadmin || user.role.allowed_pages.includes("/faq");

  return (
    <div className="container-fluid mt-2">
      <section className="row my-4">
        <div className="col-sm-4">
          <h2>
            <b>Questions</b>
          </h2>
        </div>
        <div className="col-sm-8">
          {authorized && (
            <Link className="btn btn-info add-new float-right" title="New Question" to={`/faq/questions/new`}>
              <FaPlus /> New Question
            </Link>
          )}
        </div>
        <div className="col-sm-12">
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title"></div>
              <table className="table table-striped table-hover table-bordered bg-white">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Question</th>
                    <th className="text-center" style={{ width: "120px" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map((question, index) => (
                    <tr key={"q" + question.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{question.question}</td>
                      <td className="text-center actions">
                        {authorized && (
                          <>
                            <span
                              className="action-btn text-danger px-1"
                              title="Delete"
                              onClick={() => handleDeleteQuestion(question.id)}
                            >
                              <FaTrash />
                            </span>
                            <Link
                              className="action-btn text-success px-1"
                              title="Edit Question"
                              to={`/faq/questions/${question.id}`}
                            >
                              <FaTools />
                            </Link>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <hr style={{ border: "0.5px solid #ccc", marginTop: 32, marginBottom: 32 }} />
      <section className="row my-4">
        <div className="col-sm-4">
          <h2>
            <b>Library</b>
          </h2>
        </div>
        <div className="col-sm-8">
          {authorized && (
            <Link className="btn btn-info add-new float-right" title="New Question" to={`/faq/library/new`}>
              <FaPlus /> New KB
            </Link>
          )}
        </div>
        <div className="col-sm-12">
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title"></div>
              <table className="table table-striped table-hover table-bordered bg-white">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Topic</th>
                    <th className="text-center" style={{ width: "120px" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {libraries.map((library, index) => (
                    <tr key={"l" + library.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{library.topic}</td>
                      <td className="text-center actions">
                        {authorized && (
                          <>
                            <span
                              className="action-btn text-danger px-1"
                              title="Delete"
                              onClick={() => handleDeleteLibrary(library.id)}
                            >
                              <FaTrash />
                            </span>
                            <Link
                              className="action-btn text-success px-1"
                              title="Edit Topic"
                              to={`/faq/library/${library.id}`}
                            >
                              <FaTools />
                            </Link>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQ;
