import React from "react";
import { GrShare } from "react-icons/gr";

const WayFinding = ({
  googleURL = "",
  websiteURL = "",
  propertyMapURL = "",
  mapEditURL = "",
  allowEdit = false,
  refFile,
  openNearbyFacilitiesModal,
  isAdminPage,
}) => {
  return (
    <>
      {!isAdminPage && (
        <>
          <a href="#" rel="noreferrer" onClick={openNearbyFacilitiesModal}>
            <span className="m-0 my-1">Nearby Facilities</span>
          </a>
          <br />
          <br />
        </>
      )}
      <a href={googleURL} target="_blank" rel="noreferrer">
        <p className="m-0 my-1">
          Google Map
          <GrShare className="icon mx-1" />
        </p>
      </a>
      {propertyMapURL ? (
        <a href={propertyMapURL} target="_blank" rel="noreferrer">
          <p className="m-0 my-1">
            Property Map
            <GrShare className="icon mx-1" />
          </p>
        </a>
      ) : (
        <p className="m-0 my-1">
          Property Map
          <GrShare className="icon mx-1" />
        </p>
      )}
      {allowEdit && (
        <div>
          <input type="file" ref={refFile} />
        </div>
      )}

      {mapEditURL && (
        <a target="_blank" href={mapEditURL} rel="noreferrer">
          <p className="m-0 my-1">
            Interactive Map
            <GrShare className="icon mx-1" />
          </p>
        </a>
      )}
      <a href={websiteURL.replace(/\s+/g, "-")} target="_blank" rel="noreferrer" className="m-0">
        <p>
          Website
          <GrShare className="icon mx-1" />
        </p>
      </a>
    </>
  );
};

export default WayFinding;
