import axios from "axios";

const { BASE_URL } = process.env;

const API = axios.create({
  baseURL: BASE_URL,
});

// API.defaults.headers.common.Authorization = API_KEY

API.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default API;
