import React, { useState } from "react";
import { Button, DropDown, Input } from "../../../components";

const DATA_SEARCH_DEFAULT = {
  Units: [],
  CellPhone: "",
  EMail: "",
  locationCode: null,
  locationName: "",
  TenantID: null,
  FirstName: "",
  LastName: "",
  UnitNumber: "",
};

const UNIT_DEFAULT = {
  LeaseNumber: "",
  UnitNumber: "",
};

const formatUnits = (units = DATA_SEARCH_DEFAULT.Units) => {
  return units.map((item) => ({
    value: item ? item.LeaseNumber : null,
    title: item ? item.UnitNumber : null,
  }));
};

const SearchResult = ({ result = DATA_SEARCH_DEFAULT, onSubmit }) => {
  const { Units, CellPhone: phone, EMail: email } = result;

  const unitOptions = formatUnits(Units);

  const [paymentAmount, setPaymentAmount] = useState(0);
  const [leaseNumber, setLeaseNumber] = useState(unitOptions[0].value);

  const handleSendLink = async (data, type) => {
    const submitData = {
      data,
      type,
      settlementAmount: Number(paymentAmount),
      facilityId: result.locationCode,
      facilityName: result.locationName,
      leaseNo: leaseNumber,
      unitNo: result.Units.find((unit) => unit.LeaseNumber === leaseNumber).UnitNumber,
      userId: result.TenantID,
      userName: `${result.FirstName} ${result.LastName}`,
    };
    return onSubmit(submitData);
  };
  return (
    <div className="my-2">
      <DropDown title="Select Unit" options={unitOptions} onChange={(e) => setLeaseNumber(e.target.value)} />
      <Input
        type="number"
        value={parseFloat(paymentAmount).toFixed(2)}
        title="Payment Amount"
        onChange={(e) => setPaymentAmount(e.target.value)}
      />
      <div className="row justify-content-between">
        {email ? <Button title="Send link via Email" onPress={() => handleSendLink(email, "email")} /> : null}

        {phone ? <Button title="Send link via SMS" onPress={() => handleSendLink(phone, "sms")} /> : null}
      </div>
    </div>
  );
};

export default SearchResult;
