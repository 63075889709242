import { SNAPSHOT_DETAIL_KEYS } from "../../utilities/constants";

const titleComponents = [
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_ADDRESS,
    label: "Address",
    locationKey: "address",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_CITY,
    label: "City",
    locationKey: "city",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_STATE,
    label: "State",
    locationKey: "state",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_ZIP,
    label: "Zip",
    locationKey: "zip",
  },
];

const locationInfos = [
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_NICKNAME,
    label: "Nickname",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_PRONUNCIATION,
    label: "Pronunciation",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_PHONE,
    label: "Phone Number",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_COUNT,
    label: "Total Units",
    inputType: "number",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_TIMEZONE,
    label: "Timezone",
    isSelectTimezones: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_LANDMARK,
    label: "Helpful Landmark",
  },
];

const gateAccess = [
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_ACCESS_HOURS,
    label: "Access Hours",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_AUCTION_WINNER,
    label: "Auction Winner",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_EMERGENCY_CODE,
    label: "Emergency Code",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_TEMP_CODE,
    label: "Temp Code",
  },
];

const propertyInfos = [
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_DATE_OF_ACQUISITION,
    label: "Date of Acquisition",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_PREVIOUS_OWNER,
    label: "Previous Owner",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_KIOSK,
    label: "Kiosk",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_GATE,
    label: "Gate",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_FENCE,
    label: "Fence",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_CAMERAS,
    label: "Cameras",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_TRUCK_ACCESS,
    label: "Truck Access",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_FLOOR_COUNT,
    label: "Floors",
    inputType: "number",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_ELEVATOR_TYPE,
    label: "Elevators",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.INTERIOR_UNIT_ACCESS_CODE,
    label: "Interior Unit Access Code",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.LOCAL_TOW_COMPANY,
    label: "Local Tow Company",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.TOW_COMPANY_PHONE,
    label: "Tow Company Phone",
  },
];

const unitAmenities = [
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_HEIGHT,
    label: "Unit Height",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_UNITS,
    label: "Climate Controlled Units",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_TYPE,
    label: "Climate Controlled Unit Type",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_RANGE,
    label: "Climate Controlled Range",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_RV_RANKING,
    label: "RV Parking",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.RV_AND_BOAT_ONLY,
    label: "RV and Boat Only",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_INDOOR_PARKING,
    label: "Indoor Parking",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_OUTDOOR_PARKING,
    label: "Outdoor Parking",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_SURFACE,
    label: "Parking Surface",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_OUTSIDE_UNITS,
    label: "Outside Gate Units",
    isSelect: true,
  },
];

const billingAndInsurance = [
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_INSURANCE,
    label: "Insurance",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_PROTECTION,
    label: "Protection Plan",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_INSURANCE_LEVELS,
    label: "Insurance/Protection Levels",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_TAX,
    label: "Unit Tax",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_TAX,
    label: "Parking Tax",
    isSelect: true,
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_TAX_RATE,
    label: "Unit Tax Rate",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_TAX_RATE,
    label: "Parking Tax Rate",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_LATE_FEE_SCHEME,
    label: "Late Fee Structure",
  },
  {
    key: SNAPSHOT_DETAIL_KEYS.FACILITY_LIEN_FEE,
    label: "Lien Fee",
  },
];

const timezonesInUS = [
  { name: "AST", info: "Atlantic Standard Time (AST)", seconds: -14400 },
  { name: "MIT", info: "Midway Islands Time (MIT)", seconds: -39600 },
  { name: "HST", info: "Hawaii-Aleutian Standard Time (HST)", seconds: -36000 },
  { name: "AKST", info: "Alaskan Standard Time (AKST)", seconds: -32400 },
  { name: "AKDT", info: "Alaskan Daylight Savings Time (AKDT)", seconds: -28800 },
  { name: "PST", info: "Pacific Standard Time (PST)", seconds: -28800 },
  { name: "PDT", info: "Pacific Daylight Savings Time (PDT)", seconds: -25200 },
  { name: "MST", info: "Mountain Standard Time (MST)", seconds: -25200 },
  { name: "MDT", info: "Mountain Daylight Savings Time (MDT)", seconds: -21600 },
  { name: "CST", info: "Central Standard Time (CST)", seconds: -21600 },
  { name: "CDT", info: "Central Daylight Savings Time (CDT)", seconds: -18000 },
  { name: "EST", info: "Eastern Standard Time (EST)", seconds: -18000 },
  { name: "EDT", info: "Eastern Daylight Savings Time (EDT)", seconds: -14400 },
  { name: "PRT", info: "Puerto Rico and US Virgin Islands Time (PRT)", seconds: -14400 },
];

export { titleComponents, locationInfos, gateAccess, propertyInfos, unitAmenities, billingAndInsurance, timezonesInUS };
