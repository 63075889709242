import PropTypes from "prop-types";
import React from "react";

const CustomSelect = ({ keyData, label, value, onChangeHandler = () => {}, options }) => (
  <div className="row my-1">
    <div className="col d-flex align-items-center">
      <p className="m-0">{label}:</p>
    </div>
    <select className="form-control col m-0" onChange={onChangeHandler} name={keyData} value={value}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

CustomSelect.defaultProps = {
  options: [
    { label: "No", value: false },
    { label: "Yes", value: true },
  ],
};

CustomSelect.propTypes = {
  keyData: PropTypes.string,
  label: PropTypes.string,
  onChangeHandler: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
};

export default CustomSelect;
