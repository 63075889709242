import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import { FaMinusCircle } from "react-icons/fa";

function LocationCmsEdit(props) {
  const [keywords, setKeywords] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [photoTexts, setPhotoTexts] = useState([]);

  const [body1, setBody1] = useState("");
  const [selectedTab1, setSelectedTab1] = useState("write");

  const [body2, setBody2] = useState("");
  const [selectedTab2, setSelectedTab2] = useState("write");

  const [websiteMoveInCompletionScreen, setWebsiteMoveInCompletionScreen] = useState("");
  const [selectedTab3, setSelectedTab3] = useState("write");

  const [kioskMoveInCompletionScreen, setKioskMoveInCompletionScreen] = useState("");
  const [selectedTab4, setSelectedTab4] = useState("write");

  const [title, setTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [facilityImage, setFacilityImage] = useState("");
  const [facilityImageAlt, setFacilityImageAlt] = useState("");
  const [accessHours, setAccessHours] = useState("");
  const [customerServiceHours, setCustomerServiceHours] = useState("");
  const [photo, setPhoto] = useState("");
  const [locationDetail, setLocationDetail] = useState({});
  const [unitSizeHighToLow, setUnitSizeHighToLow] = useState(false);
  const [unitGroupLargetToSmall, setUnitGroupLargetToSmall] = useState(false);
  const [recommendedUnit, setRecommendedUnit] = useState(false);
  const [unitGroupsExpanded, setUnitGroupsExpanded] = useState(false);
  const [feeFor24HourAccess, setFeeFor24HourAccess] = useState(false);

  const { location_code: locationCode } = props.match.params;

  const fetchLocationContent = () => {
    axios.get(`/api/location_contents/${locationCode}`).then((res) => {
      const locationContent = res.data;

      if (!locationContent) {
        alert("Location code is not valid.");
        props.history.push("/locations");
        return;
      }
      setBody1(locationContent.body1);
      setBody2(locationContent.body2);
      setKeywords(locationContent.keywords || []);
      setTitle(locationContent.title);
      setMetaTitle(locationContent.meta_title);
      setMetaDescription(locationContent.meta_description);
      setFacilityImage(locationContent.facility_image);
      setFacilityImageAlt(locationContent.facility_image_alt);
      setAccessHours(locationContent.access_hours);
      setCustomerServiceHours(locationContent.customer_service_hours);
      setWebsiteMoveInCompletionScreen(locationContent.website_move_in_completion_screen);
      setKioskMoveInCompletionScreen(locationContent.kiosk_move_in_completion_screen);
      setUnitSizeHighToLow(locationContent.unit_size_high_to_low);
      setUnitGroupLargetToSmall(locationContent.unit_group_large_to_small);
      setRecommendedUnit(locationContent.recommended_unit);
      setUnitGroupsExpanded(locationContent.unit_groups_expanded);
      setFeeFor24HourAccess(locationContent.fee_for_24hr_access);
      setPhotos(locationContent.photos || []);
      setPhotoTexts(locationContent.photo_texts || []);
    });
  };

  const fetchLocationDetail = () => {
    axios.get(`/api/locations/${props.location.state.locationId}`).then((res) => {
      setLocationDetail(res.data);
      setPhoto(res.data.photo);
    });
  };

  useEffect(() => {
    fetchLocationContent();
    fetchLocationDetail();
  }, []);

  const newKeyword = () => {
    setKeywords(keywords.concat(""));
  };

  const changeKeyword = (index, value) => {
    keywords[index] = value;
    setKeywords([...keywords]);
  };

  const removeKeyword = (index) => {
    keywords.splice(index, 1);
    setKeywords([...keywords]);
  };

  const newPhoto = () => {
    setPhotos(photos.concat(""));
    setPhotoTexts(photoTexts.concat(""));
  };

  const removePhoto = (index) => {
    photos.splice(index, 1);
    setPhotos([...photos]);

    photoTexts.splice(index, 1);
    setPhotoTexts([...photoTexts]);
  };

  const changePhoto = (index, value) => {
    photos[index] = value;
    setPhotos([...photos]);
  };

  const changePhotoText = (index, value) => {
    photoTexts[index] = value;
    setPhotoTexts([...photoTexts]);
  };

  const handleSubmit = async () => {
    const data = {
      title: title,
      meta_title: metaTitle,
      meta_description: metaDescription,
      facility_image: facilityImage.trim(),
      facility_image_alt: facilityImageAlt,
      access_hours: accessHours,
      customer_service_hours: customerServiceHours,
      keywords: keywords,
      body1: body1,
      body2: body2,
      website_move_in_completion_screen: websiteMoveInCompletionScreen,
      kiosk_move_in_completion_screen: kioskMoveInCompletionScreen,
      unit_size_high_to_low: unitSizeHighToLow,
      unit_group_large_to_small: unitGroupLargetToSmall,
      recommended_unit: recommendedUnit,
      unit_groups_expanded: unitGroupsExpanded,
      fee_for_24hr_access: feeFor24HourAccess,
      photos: photos,
      photo_texts: photoTexts,
    };

    try {
      const res = await axios.put(`/api/locations/${props.location.state.locationId}`, { ...locationDetail, photo });
      const res2 = await axios.put(`/api/location_contents/${locationCode}`, data);
      if (res.status === 200 && res2.status === 200) {
        alert("Successfully updated the contents!");
      } else {
        alert("Failed to update contents.");
      }
    } catch (error) {
      alert("Failed to update contents.");
    }
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Edit CMS Location Content</h1>

      <div className="row py-2">
        <div className="col-4 text-right">Location Code:</div>
        <div className="col-8 text-left">
          <input type="text" disabled={true} defaultValue={locationCode} />
        </div>
      </div>
      <div className="d-flex justify-content-end py-2" style={{ gap: "3rem" }}>
        <div className="">
          <label htmlFor="unit_size_high_to_low">Unit Size High To Low</label>
          <input
            type="checkbox"
            id="unit_size_high_to_low"
            checked={unitSizeHighToLow}
            className="mx-2"
            onChange={(e) => setUnitSizeHighToLow(e.target.checked)}
          />
        </div>
        <div className="">
          <label htmlFor="unit_group_large_to_small">Reverse Group Order</label>
          <input
            type="checkbox"
            id="unit_group_large_to_small"
            checked={unitGroupLargetToSmall}
            className="mx-2"
            onChange={(e) => setUnitGroupLargetToSmall(e.target.checked)}
          />
        </div>
        <div className="">
          <label htmlFor="recommended_unit">Recommended Unit</label>
          <input
            type="checkbox"
            id="recommended_unit"
            checked={recommendedUnit}
            className="mx-2"
            onChange={(e) => setRecommendedUnit(e.target.checked)}
          />
        </div>
        <div className="">
          <label htmlFor="unit_groups_expanded">Unit Groups Expanded</label>
          <input
            type="checkbox"
            id="unit_groups_expanded"
            checked={unitGroupsExpanded}
            className="mx-2"
            onChange={(e) => setUnitGroupsExpanded(e.target.checked)}
          />
        </div>
        <div className="">
          <label htmlFor="24_hour_access_fee">24 Hour Access Fee</label>
          <input
            type="checkbox"
            id="24_hour_access_fee"
            checked={feeFor24HourAccess}
            className="mx-2"
            onChange={(e) => setFeeFor24HourAccess(e.target.checked)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Reservation Window:</div>
        <div className="col-2 text-left">
          <input
            type="number"
            className="form-input w-100 text-right"
            min={1}
            max={21}
            value={locationDetail.reservation_window}
            onChange={(e) => setLocationDetail({ ...locationDetail, reservation_window: e.target.value })}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Title:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input w-100" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Meta Title:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Meta Description:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Keywords (Deprecated):</div>
        <div className="col-8 text-left">
          {keywords.map((keyword, index) => (
            <div key={"keyword" + index}>
              <input
                type="text"
                className="form-input mb-1 w-75"
                value={keyword}
                onChange={(e) => changeKeyword(index, e.target.value)}
              />
              <span
                className="ml-3 cursor-pointer action-btn text-danger"
                title="Delete Keyword"
                onClick={() => removeKeyword(index)}
              >
                <FaMinusCircle />
              </span>
            </div>
          ))}
          <button className="btn btn-primary" onClick={newKeyword}>
            Add New Keyword
          </button>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Facility Image Location:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={facilityImage}
            onChange={(e) => setFacilityImage(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Photo:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input w-100" value={photo} onChange={(e) => setPhoto(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Photo Alt:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={facilityImageAlt}
            onChange={(e) => setFacilityImageAlt(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Photos (Gallery):</div>
        <div className="col-8 text-left">
          {photos.map((photo, index) => (
            <div key={"image" + index} className="d-flex" style={{ gap: "1rem" }}>
              <input
                type="text"
                placeholder="Image Url"
                className="form-input mb-1"
                value={photo}
                style={{ flex: "1" }}
                onChange={(e) => changePhoto(index, e.target.value)}
              />
              <input
                type="text"
                placeholder="Image Alt"
                className="form-input mb-1"
                value={photoTexts[index]}
                style={{ flex: "1" }}
                onChange={(e) => changePhotoText(index, e.target.value)}
              />
              <span
                className="mx-2 cursor-pointer action-btn text-danger"
                title="Delete Photo"
                style={{ flex: "none" }}
                onClick={() => removePhoto(index)}
              >
                <FaMinusCircle />
              </span>
            </div>
          ))}
          <button className="btn btn-primary" onClick={newPhoto}>
            Add Photo
          </button>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Access Hours:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={accessHours}
            onChange={(e) => setAccessHours(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Customer Service Hours:</div>
        <div className="col-8 text-left">
          <textarea
            rows={4}
            className="form-input w-100"
            value={customerServiceHours}
            onChange={(e) => setCustomerServiceHours(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Body1:</div>
        <div className="col-8 text-left">
          <ReactMde
            value={body1}
            onChange={setBody1}
            selectedTab={selectedTab1}
            onTabChange={setSelectedTab1}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Body2:</div>
        <div className="col-8 text-left">
          <ReactMde
            value={body2}
            onChange={setBody2}
            selectedTab={selectedTab2}
            onTabChange={setSelectedTab2}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">
          Website Move In Completion Screen
          <br />
          <small className="text-danger">
            <span className="text-info">{"{reviewLink}"}</span> will be replaced with actual review link on website.
          </small>
        </div>
        <div className="col-8 text-left">
          <ReactMde
            value={websiteMoveInCompletionScreen}
            onChange={setWebsiteMoveInCompletionScreen}
            selectedTab={selectedTab3}
            onTabChange={setSelectedTab3}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Kiosk Move In Completion Screen</div>
        <div className="col-8 text-left">
          <ReactMde
            value={kioskMoveInCompletionScreen}
            onChange={setKioskMoveInCompletionScreen}
            selectedTab={selectedTab4}
            onTabChange={setSelectedTab4}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/locations">
            Back
          </Link>
          <button className="btn btn-primary px-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default LocationCmsEdit;
