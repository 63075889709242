import React from "react";

const inputStyle = {
  fontSize: 20,
  fontFamily: "Myriad Pro Semibold",
};

const CustomInput = ({ keyData, value = "", type = "", label, haveLabel = false, onChangeHandler = () => {} }) => {
  if (haveLabel) {
    return (
      <div className="row my-1">
        <div className="col d-flex align-items-center">
          <p className="m-0">{label}:</p>
        </div>
        <input
          className="form-control col-6"
          type={type}
          onChange={onChangeHandler}
          style={inputStyle}
          name={keyData}
          value={value}
        />
      </div>
    );
  }
  return (
    <input
      style={inputStyle}
      onChange={onChangeHandler}
      name={keyData}
      value={value}
      placeholder={label}
      type={type}
      className="form-control mx-1"
    />
  );
};

export default CustomInput;
