import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaTrash, FaPlus, FaTools } from "react-icons/fa";

function Settings() {
  const [settings, setSettings] = useState([]);

  const fetchSettings = () => {
    axios.get("/api/settings").then((res) => {
      setSettings(res.data || []);
    });
  };

  const handleDeleteSetting = (id) => {
    if (window.confirm("Are you sure you want to delete this setting?")) {
      axios.delete("/api/settings/" + id).then((res) => {
        if (res.status === 200) {
          fetchSettings();
        }
      });
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div className="container-fluid mt-2">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-4">
                <h2>
                  <b>Settings</b>
                </h2>
              </div>
              <div className="col-sm-8">
                <Link className="btn btn-info add-new float-right" title="New Setting" to={`/settings/new`}>
                  <FaPlus /> New Setting
                </Link>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Description</th>
                <th>Name</th>
                <th>Value</th>
                <th className="text-center" style={{ width: "120px" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {settings.map((setting, index) => (
                <tr key={"s_" + setting.id}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{setting.description}</td>
                  <td className="text-center">{setting.name}</td>
                  <td className="text-center">{setting.value}</td>
                  <td className="text-center actions">
                    <span
                      className="action-btn text-danger px-1"
                      title="Delete"
                      onClick={() => handleDeleteSetting(setting.id)}
                    >
                      <FaTrash />
                    </span>
                    <Link className="action-btn text-success px-1" title="Edit Setting" to={`/settings/${setting.id}`}>
                      <FaTools />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Settings;
