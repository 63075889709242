import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { Prompt } from "react-router-dom";
import { getLocationList } from "../../../utilities/common";
import SNAPSHOT_SERVICE from "../../../services/snapshot.service";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { BreadCrumb, Loader } from "../../../components";
import { Link } from "react-router-dom";
import "../style.css";
import { SNAPSHOT_DETAIL_KEYS } from "../../../utilities/constants";
import CustomInput from "../components/CustomInput";
import WayFinding from "../components/WayFinding";
import { getCmsLink } from "../../../utilities/helper";
import CustomSelect from "../components/CustomSelect";
import {
  billingAndInsurance,
  gateAccess,
  locationInfos,
  propertyInfos,
  timezonesInUS,
  unitAmenities,
} from "../constants";
import { Helmet } from "react-helmet";
import { PATH } from "../../../routers";

const initialValue = {
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ALERTS]: "",
  // Title
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ADDRESS]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_CITY]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_STATE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ZIP]: "",
  // Location info
  [SNAPSHOT_DETAIL_KEYS.FACILITY_NICKNAME]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PRONUNCIATION]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_COUNT]: 0,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PHONE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_TIMEZONE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_LANDMARK]: "",
  // Property info
  [SNAPSHOT_DETAIL_KEYS.FACILITY_DATE_OF_ACQUISITION]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PREVIOUS_OWNER]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_KIOSK]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_GATE]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_FENCE]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_CAMERAS]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_TRUCK_ACCESS]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_FLOOR_COUNT]: 0,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ELEVATOR_TYPE]: "",
  [SNAPSHOT_DETAIL_KEYS.INTERIOR_UNIT_ACCESS_CODE]: "",
  // Gate access
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ACCESS_HOURS]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_EMERGENCY_CODE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_TEMP_CODE]: "",
  // Unit Amenities
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_HEIGHT]: "",
  [SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_UNITS]: false,
  [SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_TYPE]: "",
  [SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_RANGE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_RV_RANKING]: false,
  [SNAPSHOT_DETAIL_KEYS.RV_AND_BOAT_ONLY]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_INDOOR_PARKING]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_OUTDOOR_PARKING]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_OUTSIDE_UNITS]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_SURFACE]: "",
  // Unique Detail
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIQUE_DETAILS]: "",
  // Billing and Insurance
  [SNAPSHOT_DETAIL_KEYS.FACILITY_INSURANCE]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PROTECTION]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_INSURANCE_LEVELS]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_TAX]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_TAX]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_TAX_RATE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_TAX_RATE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_LATE_FEE_SCHEME]: "",

  [SNAPSHOT_DETAIL_KEYS.PROPERTY_MAP]: "",
};

const AdminSnapShotDetail = (props) => {
  let {
    match: {
      params: { slug },
    },
  } = props;
  const history = useHistory();
  const locationList = getLocationList();

  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(true);
  const selectedFile = useRef(null);
  const [isDirty, setIsDirty] = useState(false);

  const location = locationList.find((location) => location.slug === slug);

  const getDetail = async () => {
    if (location["location_code"]) {
      try {
        const snapshotDetail = await SNAPSHOT_SERVICE.getSnapShotDetail(location["location_code"]);
        if (snapshotDetail) {
          setData(snapshotDetail);
        }
      } catch (error) {
        const message = error.response.data;
        alert(message);
        history.push("/snapshot");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const newData = {
      ...data,
      [e.target.name]: value,
    };
    if (!isDirty) setIsDirty(true);
    setData(newData);
  };

  const handleSaveData = async () => {
    const snapshotDetail = { ...data, location_code: location["location_code"] };
    const formData = new FormData();
    formData.append("upload_file", selectedFile.current.files[0]);
    formData.append("slug", location.slug);
    Object.keys(snapshotDetail).forEach((key) => {
      formData.append(key, snapshotDetail[key] !== null ? snapshotDetail[key] : initialValue[key]);
    });
    return SNAPSHOT_SERVICE.saveSnapShotDetail(formData)
      .then((result) => {
        if (result.status === 200) {
          setIsDirty(false);
          alert(result.data);
        }
      })
      .catch((error) => alert(error));
  };

  const renderListItems = (listItems = []) => {
    return listItems.map((item) => {
      const { label, key } = item;
      if (item.isSelect) {
        return (
          <CustomSelect key={key} value={data[key]} keyData={key} label={label} onChangeHandler={onChangeHandler} />
        );
      }
      if (item.isSelectTimezones) {
        const options = timezonesInUS.map((timezone) => ({
          value: timezone.name,
          label: `${timezone.info} ${timezone.seconds < 0 ? "-" : "+"}${
            Math.abs(timezone.seconds / 3600) < 10
              ? "0" + Math.abs(timezone.seconds / 3600)
              : Math.abs(timezone.seconds / 3600)
          }:00`,
        }));
        return (
          <CustomSelect
            key={key}
            value={data[key]}
            keyData={key}
            label={label}
            onChangeHandler={onChangeHandler}
            options={options}
          />
        );
      }
      return (
        <CustomInput
          key={key}
          haveLabel
          keyData={key}
          value={data[key]}
          label={label}
          onChangeHandler={onChangeHandler}
          type={item.inputType || "text"}
        />
      );
    });
  };

  return (
    <div>
      <Prompt message="Wait! You have unsaved changes. Are you sure?" when={isDirty} />
      <Helmet>
        <title>Snapshot</title>
      </Helmet>
      <SnapShotHeader />
      <div className="bg-light">
        <div className="col-10">
          <BreadCrumb title="Search" path={PATH.ADMIN_SNAPSHOT} />
        </div>
        {loading ? (
          <div className="d-flex justify-content-center pt-2">
            <Loader />
          </div>
        ) : (
          <div className="col-12 min-vh-100 m-0 pt-2 pb-4">
            <div className="row justify-content-end m-2">
              <Link className="btn btn-primary" title="New State" to={`/`}>
                Back To Main Page
              </Link>
            </div>

            <div className="col-10 mx-auto">
              <div className="row justify-content-center position-relative align-items-center mb-4">
                <div>
                  <p className="title">{location.name}</p>
                  <div className="row my-1">
                    <div className="col d-flex align-items-center">
                      <p className="m-0">{"Facility Alerts"}:</p>
                    </div>
                    <input
                      name={SNAPSHOT_DETAIL_KEYS.FACILITY_ALERTS}
                      className="form-control col-6"
                      onChange={onChangeHandler}
                      value={data[SNAPSHOT_DETAIL_KEYS.FACILITY_ALERTS] || ""}
                    />
                  </div>
                </div>
                <div className="w-100 position-absolute">
                  <button className="btn btn-success align-middle float-right" onClick={handleSaveData}>
                    Save
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  <p className={"title-block"}>Location Information</p>
                  {renderListItems(locationInfos)}
                </div>

                <div className="col-4">
                  <p className={"title-block"}>Gate Access</p>
                  {renderListItems(gateAccess)}
                </div>

                <div className="col-3">
                  <p className={"title-block"}>Unique Details</p>
                  <textarea
                    cols={25}
                    rows={4}
                    name={SNAPSHOT_DETAIL_KEYS.FACILITY_UNIQUE_DETAILS}
                    onChange={onChangeHandler}
                    value={data[SNAPSHOT_DETAIL_KEYS.FACILITY_UNIQUE_DETAILS]}
                    className="form-control"
                  />
                </div>

                <div className="col-2">
                  <p className={"title-block"}>Wayfinding</p>
                  <WayFinding
                    googleURL={`http://maps.google.com/?q=sparebox storage ${location.address} ${location.state} ${location.zip}&z=22&t=k`}
                    propertyMapURL={
                      data[SNAPSHOT_DETAIL_KEYS.PROPERTY_MAP] &&
                      getCmsLink() + "/img/" + data[SNAPSHOT_DETAIL_KEYS.PROPERTY_MAP]
                    }
                    websiteURL={`${getCmsLink()}/storage-units/${location.state.toLowerCase()}/${location.city.toLowerCase()}/${location.slug.toLowerCase()}`}
                    allowEdit
                    refFile={selectedFile}
                    openNearbyFacilitiesModal={() => {}}
                    isAdminPage={true}
                  />
                </div>

                <div className="col-3">
                  <p className={"title-block"}>Property Information</p>
                  {renderListItems(propertyInfos)}
                </div>

                <div className="col-4">
                  <p className={"title-block"}>Unit Amenities</p>
                  {renderListItems(unitAmenities)}
                </div>

                <div className="col-4">
                  <p className={"title-block"}>Billing and Insurance</p>
                  {renderListItems(billingAndInsurance)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminSnapShotDetail;
