import React, { useEffect, useState } from "react";
import { Input, Button } from "../../components";
import RoleService from "../../services/roles";

const RoleForm = ({ editData, onClose, onFinish }) => {
  const [name, setName] = useState(editData?.name);

  useEffect(() => {
    setName(editData?.name || "");
  }, [editData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editData) {
      const data = editData;
      data.name = name;
      await RoleService.updateRole(data);
    } else {
      const data = { name };
      await RoleService.createRole(data);
    }

    onFinish();
    onClose();
  };

  return (
    <form className="p-2" onSubmit={handleSubmit}>
      <div className="col-12">
        <Input title="Name" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="col-12 d-flex justify-content-end">
        {editData ? (
          <div className="row">
            <div className="mx-2">
              <Button title="Update" type="submit" />
            </div>
            <div className="mx-2">
              <Button title="Cancel" type="button" typeBtn="dark" onPress={onClose} />
            </div>
          </div>
        ) : (
          <Button title="Create" type="submit" />
        )}
      </div>
    </form>
  );
};

export default RoleForm;
