import React, { useEffect, useState } from "react";
import axios from "axios";
import UserForm from "./UserForm";
import { FaPen, FaTrash } from "react-icons/fa";
import UserService from "../../services/users";
import { Link } from "react-router-dom";
import { Button, Modal } from "../../components";
import { useUser } from "../../utilities/helper";
import { ROLES } from "../../utilities/constants";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [editData, setEditData] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const { user: userInfo } = useUser();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await axios.get("/api/users");
    const { data = [] } = response;
    setUsers(data);
  };

  const handleDeleteUser = async (userId) => {
    const isConfirm = confirm("Are you sure you want to delete this user?");
    if (isConfirm) {
      const response = await UserService.deleteUser(userId);
      if (response.status === 200) {
        alert("User was successfully deleted");
        setUsers(users.filter((user) => user.id !== userId));
      }
    }
  };

  const closeModal = () => {
    fetchUsers();
    setEditData(null);
    setVisibleModal(false);
  };

  return (
    <div className="container-fluid mt-2">
      <div className="mt-2">
        <div className="row justify-content-end">
          <Button
            title="Create User"
            onPress={() => {
              setVisibleModal(true);
              setEditData(null);
            }}
          />
        </div>

        <h1 className="text-center">Users</h1>

        <Modal visible={visibleModal} setVisible={closeModal} title="Users">
          <UserForm
            setUsers={setUsers}
            visible={visibleModal}
            setVisible={setVisibleModal}
            editData={editData}
            onCancelEdit={() => {
              setEditData(null);
              setVisibleModal(false);
            }}
            onCloseModal={() => {
              setEditData(null);
              setVisibleModal(false);
            }}
          />
        </Modal>

        <div className="col-10 mx-auto">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.role?.name}</td>
                  <td>
                    <button
                      className="btn text-warning px-1"
                      title="Edit"
                      onClick={() => {
                        setEditData(user);
                        setVisibleModal(true);
                      }}
                    >
                      <FaPen />
                    </button>
                    <button className="btn text-danger px-1" onClick={() => handleDeleteUser(user.id)} title="Delete">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
