export const LOCATION_FIELDS = {
  name: {
    value: "",
    label: "Name",
  },
  lat: {
    value: "",
    label: "Latitude",
  },
  long: {
    value: "",
    label: "Longitude",
  },
  address: {
    value: "",
    label: "Address",
  },
  phone: {
    value: "",
    label: "Phone Number",
  },
  search: {
    value: "",
    label: "Search",
  },
  reservation_email: {
    value: "",
    label: "Reservation Email",
  },
  fax: {
    value: "",
  },
  photo: {
    value: "",
  },
  gate_hours: {
    value: "",
  },
  office_hours: {
    value: "",
  },
  location_code: {
    value: "",
  },
  contact_name: {
    value: "",
  },
  city: {
    value: "",
  },
  state: {
    value: "",
  },
  zip: {
    value: "",
  },
  slug: {
    value: "",
  },
  xwebid: {
    value: "",
    label: "Web - xwebid",
  },
  xwebterminalid: {
    value: "",
    label: "Web - xwebterminalid",
  },
  xwebauthkey: {
    value: "",
    label: "Web - xwebauthkey",
  },
  kiosk_xwebid: {
    value: "",
    label: "Kiosk - xwebid",
  },
  kiosk_xwebterminalid: {
    value: "",
    label: "Kiosk - xwebterminalid",
  },
  kiosk_xwebauthkey: {
    value: "",
    label: "Kiosk - xwebauthkey",
  },
  w9: {
    value: "",
  },
  coming_soon: {
    type: "checkbox",
    value: false,
    label: "Coming Soon",
  },
  sboa: {
    type: "checkbox",
    value: false,
  },
  sboa_brochure: {
    value: "",
  },
  allow_edge_express_in_kiosk: {
    type: "checkbox",
    value: false,
  },
  special_instruction_after_rental: {
    value: "",
  },
  allow_ach: {
    type: "checkbox",
    value: false,
    label: "Allow ACH",
  },
  feedback_link: {
    value: "",
    label: "Feedback Link",
  },
  autopay_on_move_in: {
    type: "checkbox",
    value: false,
    label: "Autopay on Move-In",
  },
  autopay_on_payment: {
    type: "checkbox",
    value: false,
    label: "Autopay on Payment",
  },
  facility_map_enabled: {
    type: "checkbox",
    value: false,
    label: "Enable Facility Map",
  },
  kiosk_id_scanner_enabled: {
    type: "checkbox",
    value: false,
    label: "Enable Kiosk ID Scanner",
  },
  pm_email: {
    value: "",
    label: "PM Email",
  },
  sub_location: {
    value: "",
    label: "Sub Facility Location Code",
  },
  // disabled: {
  //   type: "checkbox",
  //   value: false,
  //   label: "Disable Facility",
  // },
};
