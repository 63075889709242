import React, { useState, useEffect } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import axios from "axios";

import { useUser } from "../utilities/helper";
import Header from "../components/Header";

import Homepage from "../pages/Homepage";
import Locations from "../pages/Locations";
import LocationCmsEdit from "../pages/LocationCmsEdit";
import AuctionWinners from "../pages/Auctions/modules/AuctionWinners";
import AuctionWinnerUpload from "../pages/Auctions/modules/AuctionWinnerUpload";
import LoginForm from "../pages/Auth/Login";
import DelinquentTenants from "../pages/DelinquentTenants";
import States from "../pages/States";
import StateCreate from "../pages/States/Create";
import StateEdit from "../pages/States/Edit";
import Cities from "../pages/Cities";
import CityCreate from "../pages/Cities/Create";
import CityEdit from "../pages/Cities/Edit";
import FAQ from "../pages/FAQ";
import QuestionCreate from "../pages/FAQ/modules/Questions/Create";
import QuestionEdit from "../pages/FAQ/modules/Questions/Edit";
import LibraryCreate from "../pages/FAQ/modules/Library/Create";
import LibraryEdit from "./../pages/FAQ/modules/Library/Edit";
import UsersPage from "../pages/Users";
import FileManagementPage from "../pages/FileManagementPage";
import SnapShotPage from "../pages/SnapShot";
import Settings from "../pages/Settings";
import SettingCreate from "../pages/Settings/Create";
import SettingEdit from "../pages/Settings/Edit";
import Blogs from "../pages/Blogs";
import BlogCreate from "../pages/Blogs/Create";
import BlogEdit from "../pages/Blogs/Edit";
import Pages from "../pages/Pages";
import PageCreate from "../pages/Pages/PageCreate";
import LocationMapEdit from "../pages/LocationMapEdit";
import Auctions from "../pages/Auctions";
import StreetRateUpload from "../pages/StreetRateUpload";
import TenantRateUpload from "../pages/TenantRateUpload";
import LeaseTerminations from "../pages/LeaseTerminations";
import LeaseTerminationsHistory from "../pages/LeaseTerminations/history";
import Logs from "../pages/Logs";
import RolesPage from "../pages/Roles";
import NotFound from "../pages/NotFound";
import Unauthorized from "../pages/Unauthorized";
import AdminSnapShotDetail from "../pages/SnapShotDetail/Admin";
import CommunicationTemplates from "../pages/CommunicationTemplates";
import CommunicationTemplatesEdit from "../pages/CommunicationTemplates/Edit";
import CommunicationHistory from "../pages/CommunicationHistory";
import MarketingFeatures from "../pages/MarketingFeatures";
import GateCodeRotation from "../pages/GateCodeRotation";
import LocationBulkEdit from "../pages/LocationBulkEdit";

function SwitchPages() {
  const { user, setUser } = useUser();
  const { accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);

  useEffect(() => {
    if (!Array.isArray(accounts) || !accounts.length || !isAuthenticated) {
      // we have to have an account with authenticated status to proceed to next step
      return;
    }
    findUserInfo();
  }, [accounts, isAuthenticated]);

  const findUserInfo = async () => {
    // fetching user info based on email;
    // if email already exist then log them in with required role
    // if not exist then return 404
    setLoadingUserInfo(true);
    try {
      const response = await axios.post("/api/users/find", { email: accounts[0].username });
      setUser(response.data);
    } catch (error) {
      console.log(error);
      setIsLoginFailed(true);
      localStorage.clear();
    } finally {
      setLoadingUserInfo(false);
    }
  };

  if (inProgress !== "none" || loadingUserInfo) return "Getting account info...";

  if (!user) {
    return <LoginForm setUser={setUser} isError={isLoginFailed} />;
  }

  if (user.is_clerk_role) {
    return <DelinquentTenants setUser={setUser} />;
  }

  return (
    <Router>
      {window.location.pathname !== "/azure" && <Header setUser={setUser} />}
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <ProtectedRoute exact path="/roles" component={RolesPage} />
        <ProtectedRoute exact path="/locations" component={Locations} />
        <ProtectedRoute exact path="/locations_bulk_edit" component={LocationBulkEdit} />
        <ProtectedRoute exact path="/locations/:location_code/cms_edit" component={LocationCmsEdit} />
        <ProtectedRoute exact path="/locations/:location_code/map_edit" component={LocationMapEdit} />
        <ProtectedRoute exact path="/states" component={States} />
        <ProtectedRoute exact path="/states/new" component={StateCreate} />
        <ProtectedRoute exact path="/states/:short_name" component={StateEdit} />
        <ProtectedRoute exact path="/cities" component={Cities} />
        <ProtectedRoute exact path="/cities/new" component={CityCreate} />
        <ProtectedRoute exact path="/cities/:id" component={CityEdit} />
        <ProtectedRoute exact path="/auctions" component={Auctions} />
        <ProtectedRoute exact path="/auctions/history" component={AuctionWinners} />
        <ProtectedRoute exact path="/auctions/new-upload" component={AuctionWinnerUpload} />
        <ProtectedRoute exact path="/faq" component={FAQ} />
        <ProtectedRoute exact path="/faq/questions/new" component={QuestionCreate} />
        <ProtectedRoute exact path="/faq/questions/:id" component={QuestionEdit} />
        <ProtectedRoute exact path="/faq/library/new" component={LibraryCreate} />
        <ProtectedRoute exact path="/faq/library/:id" component={LibraryEdit} />
        <ProtectedRoute exact path="/settings" component={Settings} />
        <ProtectedRoute exact path="/settings/new" component={SettingCreate} />
        <ProtectedRoute exact path="/settings/:id" component={SettingEdit} />
        <ProtectedRoute exact path="/blogs" component={Blogs} />
        <ProtectedRoute exact path="/blogs/new" component={BlogCreate} />
        <ProtectedRoute exact path="/blogs/:id" component={BlogEdit} />
        <ProtectedRoute exact path="/users" component={UsersPage} />
        <ProtectedRoute exact path="/file-management" component={FileManagementPage} />
        <ProtectedRoute exact path="/snapshot" component={SnapShotPage} />
        <ProtectedRoute exact path="/snapshot/:slug" component={AdminSnapShotDetail} />
        <ProtectedRoute exact path="/pages" component={Pages} />
        <ProtectedRoute exact path="/pages/new" component={PageCreate} />
        <ProtectedRoute exact path="/pages/edit/:id" component={PageCreate} />
        <ProtectedRoute exact path="/street-rate-upload" component={StreetRateUpload} />
        <ProtectedRoute exact path="/street-rate-upload/logs" component={Logs} />
        <ProtectedRoute exact path="/tenant-rate-upload" component={TenantRateUpload} />
        <ProtectedRoute exact path="/tenant-rate-upload/logs" component={Logs} />
        <ProtectedRoute exact path="/lease-terminations" component={LeaseTerminations} />
        <ProtectedRoute exact path="/lease-terminations/history" component={LeaseTerminationsHistory} />
        <ProtectedRoute exact path="/communication-templates" component={CommunicationTemplates} />
        <ProtectedRoute exact path="/communication-history" component={CommunicationHistory} />
        <ProtectedRoute exact path="/communication-templates/:id" component={CommunicationTemplatesEdit} />
        <ProtectedRoute exact path="/marketing-features" component={MarketingFeatures} />
        <ProtectedRoute exact path="/gate-code-rotation" component={GateCodeRotation} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

function ProtectedRoute({ component: Component, location, ...rest }) {
  const { user } = useUser();
  let authorized = user.role.superadmin || user.role.allowed_pages.some((e) => location.pathname.includes(e));
  if (authorized) {
    return <Route {...rest} location={location} render={(props) => <Component {...props} isAdmin={true} />} />;
  } else {
    return <Route component={Unauthorized} />;
  }
}

export default SwitchPages;
