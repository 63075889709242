import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FacilityMap from "../../components/FacilityMap";
import { FaCrosshairs } from "react-icons/fa";

function LocationMapEdit(props) {
  const { location_code: locationCode } = props.match.params;
  const [units, setUnits] = useState([]);
  const [message, setMessage] = useState("");
  const [floors, setFloors] = useState([]);
  const [visibleFloor, setVisibleFloor] = useState(null);
  const [gate, setGate] = useState({});
  const [rentalCenter, setRentalCenter] = useState({});
  const [selectedUnit, setSelectedUnit] = useState("");

  useEffect(() => {
    loadLocationUnits();

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        setSelectedUnit("");
      }
    });
  }, []);

  const handleSubmitCSV = (e) => {
    if (!confirm("Existing units will be cleaned when uploading new CSV. Are you sure?")) {
      return;
    }
    const data = new FormData();
    data.append("file", e.target.files[0]);

    setMessage("Importing CSV ...");
    axios
      .post(`/api/location_units/upload?location_code=${locationCode}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          loadLocationUnits();
          setMessage("Import Finished.");
        }
      });
  };

  const loadLocationUnits = () => {
    axios
      .get(`/api/location_units?location_code=${locationCode}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const units = res.data;
        setUnits(units);
        processUnits(units);
      });
  };

  const processUnits = (units) => {
    let floors = [];

    units.forEach((u) => {
      if (!floors.includes(u.floor_no)) {
        floors.push(u.floor_no);
      }
      if (u.system) {
        if (u.category_name === "Gate") {
          setGate({ x: u.left_coordinate, y: u.top_coordinate, rotation: u.system_rotation || 0, size: u.width });
        }
        if (u.category_name === "Rental Center") {
          setRentalCenter({
            x: u.left_coordinate,
            y: u.top_coordinate,
            rotation: u.system_rotation || 0,
            size: u.width,
          });
        }
      }
    });
    setFloors(floors);
    setVisibleFloor(floors[0]);
  };

  const handleSave = () => {
    updateSystemUnits();
  };

  const updateSystemUnits = async () => {
    const gateData = {
      location_code: locationCode,
      category_name: "Gate",
      left_coordinate: parseInt(gate.x),
      top_coordinate: parseInt(gate.y),
      system_rotation: gate.rotation,
      width: gate.size,
      floor_no: 1,
      system: true,
    };
    await axios.post(`/api/location_units`, gateData);

    const rentalCenterData = {
      location_code: locationCode,
      category_name: "Rental Center",
      left_coordinate: parseInt(rentalCenter.x),
      top_coordinate: parseInt(rentalCenter.y),
      system_rotation: rentalCenter.rotation,
      width: rentalCenter.size,
      floor_no: 1,
      system: true,
    };
    await axios.post(`/api/location_units`, rentalCenterData);

    loadLocationUnits();
  };

  const handleNewPosition = (e) => {
    if (selectedUnit === "gate") {
      setGate({ ...gate, x: parseInt(e.lng) - 20, y: parseInt(e.lat * -1) - 20 });
    } else if (selectedUnit === "rental_center") {
      setRentalCenter({ ...rentalCenter, x: parseInt(e.lng) - 20, y: parseInt(e.lat * -1) - 20 });
    }

    setSelectedUnit("");
  };

  return (
    <div className="container-fluid my-4">
      <h1 className="text-center">Edit Facility Map</h1>

      <div className="row">
        <div className="col-4 text-left align-self-center">
          <a href="/facility-map-sample.csv">Download Example CSV</a>
        </div>
        <div className="col-4 text-center">
          <section className="section-preview" style={{ width: 400, display: "inline-block" }}>
            <div className="input-group my-2">
              <div className="input-group-prepend">
                <span className="input-group-text">Upload</span>
              </div>
              <div className="custom-file">
                <input type="file" className="custom-file-input" accept=".csv" onChange={handleSubmitCSV} />
                <label className="custom-file-label">Choose CSV File</label>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center text-danger">{message}</div>
      </div>
      <div className="row">
        <div className="col-3 pt-4">
          <div>
            <label>
              <b>Gate:</b>
            </label>
            <br />
            <label className="w-75">
              Position:
              <input
                key="gate_x"
                type="number"
                className="mx-1 w-25"
                placeholder="x"
                value={gate.x}
                onChange={(e) => setGate({ ...gate, x: e.target.value })}
              ></input>
              <input
                key="gate_y"
                type="number"
                className="mx-1 w-25"
                placeholder="y"
                value={gate.y}
                onChange={(e) => setGate({ ...gate, y: e.target.value })}
              ></input>
            </label>
            <a
              href="#"
              className="text-success px-2 float-right"
              title="Pick a position in map"
              onClick={() => setSelectedUnit("gate")}
            >
              <FaCrosshairs />
            </a>
            <br />
            <label>
              Rotation:
              <input
                key="gate_rotation"
                type="number"
                min={0}
                max={360}
                className="mx-1"
                placeholder="Angle in Degrees"
                value={gate.rotation}
                onChange={(e) => setGate({ ...gate, rotation: e.target.value })}
              ></input>
            </label>
            <br />
            <label>
              Size:
              <input
                key="gate_size"
                type="number"
                className="mx-1"
                min={1}
                max={999}
                placeholder="10"
                value={gate.size}
                onChange={(e) => setGate({ ...gate, size: e.target.value })}
              ></input>
            </label>
          </div>
          <div className="mt-4">
            <label>
              <b>Rental Center:</b>
            </label>
            <br />
            <label className="w-75">
              Position:
              <input
                key="rental_center_x"
                type="number"
                className="mx-1 w-25"
                placeholder="x"
                value={rentalCenter.x}
                onChange={(e) => setRentalCenter({ ...rentalCenter, x: e.target.value })}
              ></input>
              <input
                key="rental_center_y"
                type="number"
                className="mx-1 w-25"
                placeholder="y"
                value={rentalCenter.y}
                onChange={(e) => setRentalCenter({ ...rentalCenter, y: e.target.value })}
              ></input>
            </label>
            <a
              href="#"
              className="text-success px-2 float-right"
              title="Pick a position in map"
              onClick={() => setSelectedUnit("rental_center")}
            >
              <FaCrosshairs />
            </a>
            <br />
            <label>
              Rotation:
              <input
                key="rental_center_rotation"
                type="number"
                min={0}
                max={360}
                className="mx-1"
                placeholder="Angle in Degrees"
                value={rentalCenter.rotation}
                onChange={(e) => setRentalCenter({ ...rentalCenter, rotation: e.target.value })}
              ></input>
            </label>
            <br />
            <label>
              Size:
              <input
                key="rental_center_size"
                type="number"
                className="mx-1"
                min={1}
                max={999}
                placeholder="10"
                value={rentalCenter.size}
                onChange={(e) => setRentalCenter({ ...rentalCenter, size: e.target.value })}
              ></input>
            </label>
            <br />
          </div>
          <div className="text-right mt-2">
            <button className="btn btn-success" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
        <div className="col-9">
          <div className="text-right">
            {floors.map((f) => (
              <label key={f} className="mr-4">
                <input
                  type="radio"
                  className="mr-2"
                  checked={visibleFloor === f}
                  onChange={() => {}}
                  onClick={(e) => setVisibleFloor(f)}
                />
                Floor {f}
              </label>
            ))}
          </div>
          {units.length > 0 ? (
            <FacilityMap
              units={units}
              visibleFloor={visibleFloor}
              interactive={selectedUnit !== ""}
              onMapClick={handleNewPosition}
            />
          ) : (
            <p className="text-center">No location units imported.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default LocationMapEdit;
