import React from "react";

const Input = ({ title, type = "text", onChange, value, autoComplete, required = false }) => {
  const inputId = `input_${title.replace(/ /g, "_")}`;
  return (
    <div className="form-group row">
      <label className="col-sm-4 d-flex align-items-center" htmlFor={inputId}>
        {title}
      </label>
      <input
        id={inputId}
        type={type}
        required={required}
        onChange={onChange}
        className="form-control col-sm-8"
        value={value}
        autoComplete={!autoComplete ? "new-password" : null}
      />
    </div>
  );
};

export default Input;
