import moment from "moment";

export const BASE_URL_ENV = {
  production: "https://pm.spareboxstorage.com",
  staging: "https://staging-pm.spareboxstorage.com",
  development: "http://staging-pm.spareboxstorage.com",
};

export const TASK_CATEGORIES = [
  {
    title: "Property Tasks",
    category: "property",
    optionLabel: "Facility",
  },
  {
    title: "Unit Tasks",
    category: "unit",
    optionLabel: "Unit",
  },
];

export const USER_TITLES = {
  customer_service: "Customer Service",
  property_manager: "Property Manager",
  regional_manager: "Regional Manager",
}


export const WARNING_DAYS_OLD = 10;
export const PAGE_SIZE = 10;
export const ROLES_LABEL_COLORS = {
  customer_service: "#D50037",
  property_manager: "#000",
  regional_manager: "#021f82",
};

export const dateDiff = (oldDate, newDate = new Date()) => {
  return moment(newDate).diff(moment(oldDate), "days");
};

export const priorOptions = [
  { value: 'urgent', label: 'Urgent', label1: 'Urgent', color: '#d50037', style: { fontWeight: 'bold' } },
  { value: 'high', label: 'High', label1: 'High Priority', color: '#d50037' },
  { value: 'medium', label: 'Medium', label1: 'Medium Priority', color: '#000000' },
  { value: 'low', label: 'Low', label1: 'Low Priority', color: '#000000' },
]
