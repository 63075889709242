import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { FaClipboard } from "react-icons/fa";
import { useUser } from "../../utilities/helper";
import LoginForm from "../Auth/Login";
import { ROLES } from "../../utilities/constants";

const FileManagementPage = () => {
  const selectedFile = useRef(null);
  const [files, setFiles] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const { user, setUser } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  if (!user) {
    return <LoginForm setUser={setUser} />;
  }

  useEffect(() => {
    const selectedIndexes = {};
    for (let i = 0; i < files.length; i++) {
      selectedIndexes[i] = checkAll;
    }
    setSelectedIndexes(selectedIndexes);
  }, [checkAll]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsUploading(true);
    const formData = new FormData();
    const listSelectedFiles = Object.values(selectedFile.current.files);

    listSelectedFiles.forEach((file, index) => {
      formData.append(`upload_files_${index}`, file);
    });

    axios
      .post("/api/file/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result.status === 200) {
          alert(result.data);
          selectedFile.current.value = "";
          loadFiles();
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = () => {
    setIsLoading(true);
    axios
      .get("/api/file")
      .then((result) => {
        setFiles(result.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const onToggleCheckAll = () => {
    setCheckAll(!checkAll);
  };

  const onBulkDeleteFiles = () => {
    if (confirm("Are you sure want to delete selected file(s)?")) {
      setIsDeleting(true);
      const fileNames = files.filter((fileName, index) => !!selectedIndexes[index]);
      axios
        .post(
          "/api/file/delete",
          { fileNames },
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            alert("Delete done successfully");
            setSelectedIndexes({});
            loadFiles();
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Delete failed");
          loadFiles();
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
  };

  const authorized = user.role.superadmin || user.role.allowed_pages.includes("/file-management");

  return (
    <div className="mt-3">
      <h1 className="text-center">File Management</h1>
      <div className="col-6 mx-auto my-3">
        <h4>New File Upload</h4>
        <div className="p-3 border">
          <input type="file" multiple name="upload_file" ref={selectedFile} />
          <input
            type="button"
            onClick={onSubmit}
            className="float-right"
            name="btn_upload_file"
            value={isUploading ? "Uploading" : "Upload"}
            disabled={isUploading}
          />
        </div>
        {authorized && Object.values(selectedIndexes).includes(true) && (
          <button
            disabled={isDeleting}
            className="btn btn-danger mb-2 mt-2 ml-auto d-block"
            onClick={onBulkDeleteFiles}
            title="Delete"
          >
            {isDeleting ? "Deleting..." : `Delete (${Object.values(selectedIndexes).filter((value) => value).length})`}
          </button>
        )}
      </div>
      {isLoading ? (
        <div className="spinner-border text-center mx-auto d-block" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="col-6 mx-auto">
          <div style={{ maxHeight: "70vh", height: "auto", overflow: "auto" }}>
            <table className="table table-striped table-bordered">
              <thead className="thead-dark" style={{ position: "sticky", top: 0 }}>
                <tr>
                  <th scope="col">
                    <input type="checkbox" checked={checkAll} onChange={onToggleCheckAll} />
                  </th>
                  <th scope="col">#</th>
                  <th scope="col">Path</th>
                  <th scope="col">URL</th>
                  <th scope="col">Preview</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr key={`${file}-${index}`}>
                    <td>
                      <input
                        type="checkbox"
                        checked={!!selectedIndexes[index]}
                        onChange={(checked) =>
                          setSelectedIndexes({ ...selectedIndexes, [index]: !selectedIndexes[index] })
                        }
                      />
                    </td>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <span>{"/img/" + file}</span>
                      <span
                        className="action-btn text-success px-1 float-right cursor-pointer"
                        title="Copy To Clipboard"
                        onClick={() => copyToClipboard("/img/" + file)}
                      >
                        <FaClipboard size="1.5em" />
                      </span>
                    </td>
                    <td>
                      <a href={cmsLink() + "/img/" + file} target="_blank" rel="noreferrer">
                        {file}
                      </a>
                    </td>
                    <td>
                      <img src={cmsLink() + "/img/" + file} width="120" height="80" loading="lazy" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

const cmsLink = () => {
  return process.env.REACT_APP_ENV === "production"
    ? "https://www.spareboxstorage.com"
    : "https://staging.spareboxstorage.com";
};

export default FileManagementPage;
